import React, { Component } from "react";
import { Button, Card, CardBody, Form, FormGroup, Row, Col, CustomInput, Modal, ModalHeader, ModalBody } from "reactstrap";
import { addComponent, editComponent, viewComponent } from "../../services/APICall";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import { withRouter } from "react-router-dom";
import { AWS_URL } from "../../config";
import ManagePayment from "../../components/manage/ManagePayment";

class SelectTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false, showModal: false };
  }
  componentDidMount = async () => {
    if (this.props.isEdit !== undefined) {
      this.setState({
        template: this.props.isEdit.template,
        resumeId: this.props.isEdit._id
      });
    } else {
      this.setState({ template: "template01" });
    }
    let templateDetailsResponse = await viewComponent("/templates");
    if (templateDetailsResponse && templateDetailsResponse.success) {
      this.setState({ templateDetails: templateDetailsResponse.data, success: true });
    } else {
      this.setState({ templateDetails: [], success: false });
    }
    console.log(this.state.templateDetails);
  };
  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };
  handleSave = async () => {
    let resume = {
      title: this.props.details.title,
      educations: this.props.details.educations,
      experiences: this.props.details.experiences,
      skills: this.props.details.skills,
      certificates: this.props.details.certificates,
      websites: this.props.details.websites,
      template: this.state.template,
      projects: this.props.details.projects
    };
    console.log(resume);
    this.state.templateDetails.forEach(ele => {
      if (ele.name === this.state.template) {
        ele.premiumStatus ? this.setState({ premiumResume: true }) : this.setState({ premiumResume: false });
      }
    });
    let proUser = await viewComponent("/premium-status");
    if (proUser && proUser.success) {
      if (proUser.data.userPremiumStatus === true || (proUser.data.userPremiumStatus === false && this.state.premiumResume === false)) {
        this.templateSave(resume);
      } else {
        this.setState({ showModal: true });
      }
    } else {
      toast.info(`Error occurred while fetching details`, { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  templateSave = async resume => {
    if (this.props.isEdit !== undefined) {
      let response = await editComponent("/resumes/", this.props.isEdit._id, resume);
      if (response && response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success(`✔️ Resume ${response.data.title} updated successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await addComponent("/create-resume", resume);
      if (response && response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success(`✔️ Resume ${response.data.title} added successfully in 'Resumes' `, { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <Form>
          <div className="py-3">
            <div className="h5">Choose a template</div>
            <Card>
              <CardBody>
                <Row form>
                  {this.state.success !== undefined
                    ? this.state.templateDetails.map((element, index) => {
                        return (
                          <div key={index}>
                            <Col sm={12}>
                              <FormGroup check className="py-2">
                                <CustomInput
                                  type="radio"
                                  name="template"
                                  value={element.name}
                                  id={element._id}
                                  checked={this.state.template === element.name}
                                  onChange={this.handleChange}
                                  label={<h6>{element.displayName}&nbsp;</h6>}
                                />
                                <div className="ribbon">
                                  <span>
                                    {element.premiumStatus ? (
                                      <>
                                        <i className="fas fa-crown" />
                                        &nbsp;Premium
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                                <ModalImage
                                  className="modalImage"
                                  small={`${AWS_URL}${element.imageUrl}`}
                                  large={`${AWS_URL}${element.imageUrl}`}
                                  alt={element.displayName}
                                  hideDownload={true}
                                  hideZoom={false}
                                />
                              </FormGroup>
                            </Col>
                          </div>
                        );
                      })
                    : ""}
                </Row>
              </CardBody>
            </Card>
          </div>
          <Button className="float-right" id="next-button" onClick={this.handleSave}>
            Save
          </Button>
        </Form>
        <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static">
          <ModalHeader close={closeBtn} className="modalStyle">
            Payment-info
          </ModalHeader>
          <ModalBody>
            <ManagePayment premiumUser={false} onClose={this.toggle} onSave={this.handleSave} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SelectTemplate);
