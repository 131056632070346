import React, { Component } from "react";
import { Card, CardImg, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { viewComponent } from "../services/APICall";
import ManagePersonalInfo from "../components/manage/ManagePersonalInfo";
import BtnSpan from "../components/BtnSpan";
import { IMG_URL } from "../config";
import { ProfileLoader } from "../constants/Loader";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = { success: undefined, showModal: false, backdrop: true, disableBackDrop: false };
  }

  componentDidMount = async () => {
    let response = await viewComponent("/personal-info");
    if (response !== null && response.success === true) {
      this.setState({ profile: response.data, success: true });
    } else {
      this.setState({ success: false });
    }
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <>
        <div className="mt-5">
          <div>
            <Card className="mt-1">
              <CardBody>
                {this.state.success === undefined ? (
                  <ProfileLoader width={345} />
                ) : this.state.success === false ? (
                  <p>There are no date.</p>
                ) : (
                  <div className="text-center">
                    <div>
                      <CardImg id="cardImg" src={IMG_URL + this.state.profile.userId + "/PI128.jpg"} alt="profile" />
                    </div>
                    <div className="my-2">
                      <div id="profileDiv" className="float-right">
                        <BtnSpan isHovering={true} onClickFunction={() => this.setState({ showModal: true })} BtnSpanClass={"fas fa-pen p-2 float-right"} />
                      </div>
                      <h5>
                        {this.state.profile.FirstName} {this.state.profile.LastName}
                      </h5>
                      <h6>{this.state.profile.headline}</h6>
                      {this.state.profile.address === undefined ? (
                        ""
                      ) : (
                        <p>
                          {"🏠 " + this.state.profile.address.street}, {this.state.profile.address.city}, {this.state.profile.address.state},{" "}
                          {this.state.profile.address.country}, {this.state.profile.address.pin}
                        </p>
                      )}
                      <p />
                      <p>
                        {this.state.profile.phone ? "📞 +91 " + this.state.profile.phone + " " : ""}
                        {""}
                      </p>
                      <p> {"📧 " + this.state.profile.Email}</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
        <Modal isOpen={this.state.showModal} toggle={this.state.disableBackDrop ? undefined : this.toggle} size="lg" backdrop={this.state.backdrop}>
          <ModalHeader close={closeBtn} className="modalStyle" toggle={this.toggle}>
            Personal Details
          </ModalHeader>
          <ModalBody className="modalScroll">
            <ManagePersonalInfo
              ref={this.child}
              disableBackDrop={enable => this.setState({ disableBackDrop: enable })}
              onClose={this.toggle}
              onSave={this.save}
              personalDetails={this.state.profile}
            />
          </ModalBody>
          <ModalFooter>
            <div className="float-right">
              <Button type="button" id="cnclBtn" className="btn  mr-3 px-4" onClick={this.toggle}>
                Cancel
              </Button>
              <Button type="button" id="saveBtn" className="btn px-4" onClick={() => this.child.current.handleSubmit()}>
                Save
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = profile => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    this.setState({ profile, success: true });
  };
}

export default Profile;
