import React from "react";

const RegistrationPending = () => (
  <div className="text-container">
    <div className="registrationPendingPAge">
      <p className="notFound">
        <i className="fas fa-envelope" />
      </p>
      <p className="not-found-text text-white p-2">
        <strong>Thank you for registering.</strong>
      </p>
      <p className="text-white p-2">An activation link has been sent to your registered email. Please verify and active your account.</p>
      <p className="text-white p-2">
        <strong>Note: The activation link will expire in 24 hrs and you will have to register again.</strong>
      </p>
      <br />
      <div className="back-btn-container">
        <a href="/login" className="back-btn">
          <span />
          🏠
          <br />
          HOME
        </a>
      </div>
    </div>
    <img src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype_negative.png" alt="logo" className="stu-logo" />
  </div>
);

export default RegistrationPending;
