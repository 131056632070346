import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Row, Col, Badge, ModalHeader, ModalBody, Modal, Card, CardBody } from "reactstrap";
import ManageSkills from "../components/manage/ManageSkills";
import { viewComponent, deleteComponent } from "../services/APICall";
import { toast } from "react-toastify";
import BtnSpan from "../components/BtnSpan";
import { TwoLineLoader } from "../constants/Loader";

const $ = window.$;
class SkillInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, skillInfo: [] };
  }
  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }
    let response = await viewComponent("/skills");

    if (response !== null && response.success) {
      this.setState({ skillInfo: response.data, success: response.success });
    } else {
      this.setState({ skillInfo: [], success: false });
    }
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newSkill, isAdd, oldSkill) => {
    let { skillInfo } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ skillInfo: [...skillInfo, newSkill] });
    } else {
      let temp = skillInfo;
      let index = temp.findIndex(obj => obj._id === oldSkill.skillId);
      temp.splice(index, 1, newSkill);
      this.setState({ skillInfo: temp });
    }
  };
  handleAdd = async () => {
    this.setState({ showModal: true, currentSkillInfo: undefined });
  };
  handleEdit = async skill => {
    this.setState({ showModal: true, currentSkillInfo: skill });
  };
  handleDelete = async skillToDelete => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/skills/", skillToDelete._id);
            if (response.success) {
              let skillObj = this.state.skillInfo.filter(data => data !== skillToDelete);
              this.setState({ skillInfo: skillObj });
              toast.success(`️️️✔️ Skill ${skillToDelete.skillName} removed successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-skill">
                  Skills
                </a>
              </h4>
            </div>

            <div id="collapse-skill" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <TwoLineLoader width={345} />
                      </div>
                    ) : this.state.skillInfo.length > 0 ? (
                      <div>
                        {this.state.skillInfo.map((currentSkill, key) => {
                          return (
                            <div
                              key={key}
                              className={key === 0 ? "p-2" : "p-2 border-top"}
                              onMouseEnter={() => this.handleMouseHover("isSkillHovering" + key, true)}
                              onMouseLeave={() => this.handleMouseHover("isSkillHovering" + key, false)}
                            >
                              <span className="float-right">
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isSkillHovering" + key] : true}
                                  onClickFunction={() => this.handleEdit(currentSkill)}
                                  BtnSpanClass={"fas fa-pen mr-3"}
                                />
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isSkillHovering" + key] : true}
                                  onClickFunction={() => this.handleDelete(currentSkill)}
                                  BtnSpanClass={"fas fa-trash"}
                                />
                              </span>
                              <Row>
                                <Col>{currentSkill.skillName}</Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Badge id="badge">{currentSkill.skillLevel}</Badge>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add your skills...!</p>
                      </div>
                    )}
                  </CardBody>
                </Card>

                <div>
                  <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static">
                    <ModalHeader close={closeBtn} className="modalStyle">
                      Skills
                    </ModalHeader>
                    <ModalBody>
                      <ManageSkills onSave={this.save} selectedSkill={this.state.currentSkillInfo} />
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SkillInfo;
