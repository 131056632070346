import React, { Component } from "react";
import { Button, Form, FormGroup, Input, FormFeedback } from "reactstrap";
import { API_URL } from "../../config";
import { toast } from "react-toastify";
import { priBtnBgColor, secBtnBgColor, cancelBtnColor } from "../../constants/Colors";

class ManageAbout extends Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false, btnSpinner: false };
  }

  componentDidMount = () => {
    this.setState({ about: this.props.value });
  };
  render() {
    const style = {
      minHeight: "120px",
      resize: "none",
      padding: "9px",
      width: "100%",
      fontSize: "15px"
    };
    return (
      <>
        <Form>
          <FormGroup>
            <Input
              invalid={this.state.isInputEmpty || this.state.isMaxInput}
              type="textarea"
              id="about"
              style={style}
              name="about"
              value={this.state.about}
              onChange={this.handleInputChange}
              placeholder="Write about yourself in not more than 150 characters"
              autoFocus
            />
            <FormFeedback>{this.state.validationMessage}</FormFeedback>
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button
              style={{ backgroundColor: secBtnBgColor, color: cancelBtnColor, border: "none" }}
              className="btn mr-3 px-4"
              onClick={() => this.props.cancelEdit()}
            >
              Cancel
            </Button>
            <Button style={{ backgroundColor: priBtnBgColor, border: "none" }} className="btn px-4" onClick={this.handleButtonUpdate}>
              {/* FIXME: remove spinner */}
              {/* {this.state.btnSpinner ? <Spinner size="sm" /> : ""} */}Save
            </Button>
            {/* <span className="ml-2">Or</span> */}
          </div>
        </Form>
      </>
    );
  }

  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });

    const length = value.length;

    if (length > 0 && length <= 150) {
      this.setState({ isInputEmpty: false, isMaxInput: false });
    }
    if (length > 150) {
      this.setState({ isMaxInput: true, validationMessage: "Max 150 words allowed" });
    }
  };

  handleButtonUpdate = async () => {
    if (this.state.about === undefined || this.state.about === "") {
      this.setState({ isInputEmpty: true, validationMessage: "Oopps! You haven't typed anything. " });
      return;
    }
    // this.setState({ btnSpinner: true });
    let response = await fetch(`${API_URL}/about-user`, {
      method: "PATCH",
      body: JSON.stringify({ about: this.state.about.trim() }),
      headers: { "Content-type": "application/json", at: localStorage.getItem("token") }
    });
    response = await response.json();
    if (response !== null && response.success === true) {
      this.props.saveChanges(response.data);
      toast.success("✔️ Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
    } else {
      toast.info("🚫 Oops! unable to update. Check your connectivity.", { position: toast.POSITION.BOTTOM_RIGHT });
    }
    // this.setState({ btnSpinner: false });
  };
}

export default ManageAbout;
