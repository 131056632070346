import React from "react";

const SuccessPage = () => (
  <div className="text-container">
    <div className="successPage">
      <p className="notFound">
        <i className="far fa-user" />
      </p>
      <p className="not-found-text text-white p-2">Your account has been successfully created.</p>

      <p className="text-white">Please login using email and password.</p>
      <div class="back-btn-container">
        <a href="/" class="back-btn">
        <span></span>
          🏠
          <br />
          HOME
        </a>
      </div>
    </div>
    <img src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype_negative.png" alt="logo" class="stu-logo" />
  </div>
);

export default SuccessPage;
