import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { viewComponent } from "../services/APICall";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import ManageWebsite from "../components/manage/ManageWebsite";
import { API_URL } from "../config";
import { toast } from "react-toastify";
import BtnSpan from "../components/BtnSpan";
import { OneLineLoader } from "../constants/Loader";

const $ = window.$;

class Website extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: undefined,
      showModal: false,
      backdrop: true,
      disableBackDrop: false,
      isHovering: false
    };
  }

  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }

    let response = await viewComponent("/websites");
    if (response !== null && response.success) {
      this.setState({ website: response.data.websites, success: true });
    } else {
      this.setState({ website: [], success: false });
    }
  };

  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <>
        <div className="panel-group mb-4">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 data-toggle="collapse" className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse_Website">
                  Websites
                </a>
              </h4>
            </div>
            <div id="collapse_Website" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <OneLineLoader width={550} />
                      </div>
                    ) : (
                      <>
                        {this.state.success === true && this.state.website.length > 0 ? (
                          this.state.website.map((site, key) => {
                            return (
                              <div
                                className={key === 0 ? "p-2" : "p-2 border-top"}
                                key={key}
                                onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                                onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                              >
                                <div className="float-right">
                                  <BtnSpan
                                    isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                    onClickFunction={() => this.handleEdit(site)}
                                    BtnSpanClass={"fas fa-pen mr-3"}
                                  />
                                  <BtnSpan
                                    isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                    onClickFunction={() => this.handleDelete(site)}
                                    BtnSpanClass={"fas fa-trash"}
                                  />
                                </div>
                                <a className="mr-5" rel="noopener noreferrer" target="_blank" href={site}>
                                  {site}
                                </a>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <div>
                              <h6>
                                <i className="far fa-frown" />
                                &nbsp; Sorry! couldn't find any details
                              </h6>
                            </div>
                            <p className="text-muted">Please add your PortFolio...!</p>
                          </div>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>

                <Modal isOpen={this.state.showModal} toggle={this.state.disableBackDrop ? undefined : this.toggle} backdrop={this.state.backdrop}>
                  <ModalHeader close={closeBtn} className="modalStyle" toggle={this.toggle}>
                    WebSite
                  </ModalHeader>
                  <ModalBody>
                    <ManageWebsite disableBackDrop={enable => this.setState({ disableBackDrop: enable })} onSave={this.save} site={this.state.site}>
                      {this.state.buttonLabel}
                    </ManageWebsite>
                  </ModalBody>
                </Modal>
              </div>
            </div>
            <br />
          </div>
        </div>
      </>
    );
  }

  // handle button hover
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };

  // to edit website address ..
  handleEdit = async site => {
    this.setState({
      site,
      showModal: true,
      buttonLabel: "Save Changes"
    });
  };

  // to delete one website address ..
  handleDelete = site => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let res = await fetch(API_URL + "/websites", {
              method: "DELETE",
              body: JSON.stringify({ websites: site }),
              headers: { "Content-Type": "application/json", at: localStorage.getItem("token") }
            });
            res = await res.json();
            if (res !== null && res.success) {
              this.setState({
                website: res.data.websites
              });
              toast.success("✔️ Deleted Succesfully.", { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };

  // to add new website address ...
  handleAdd = () => {
    this.setState({
      site: undefined,
      showModal: true,
      buttonLabel: "Save"
    });
  };

  // to open/ close model ..
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  // on edit or add, update state of website ..
  save = website => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    this.setState({
      website: website,
      success: true
    });
  };
}

export default Website;
