import React, { Component } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, InputGroupAddon, InputGroup, FormFeedback } from "reactstrap";
import { API_URL } from "../../config";
import moment from "moment";
import { toast } from "react-toastify";

class ManagePersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({
      first_name: this.props.personalDetails.FirstName,
      last_name: this.props.personalDetails.LastName,
      dateOfBirth: this.props.personalDetails.dob && moment(this.props.personalDetails.dob).format("YYYY-MM-DD"),
      gender: this.props.personalDetails.gender,
      contact: this.props.personalDetails.phone,
      website: this.props.personalDetails.website,
      mail: this.props.personalDetails.Email,
      street: this.props.personalDetails.address && this.props.personalDetails.address.street,
      city: this.props.personalDetails.address && this.props.personalDetails.address.city,
      inputState: this.props.personalDetails.address && this.props.personalDetails.address.state,
      country: this.props.personalDetails.address && this.props.personalDetails.address.country,
      pinCode: this.props.personalDetails.address && this.props.personalDetails.address.pin,
      headline: this.props.personalDetails.headline
    });

    // to enable backDrop 🙃
    this.props.disableBackDrop(false);
  }
  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });

    // to disable backDrop if user start type or updating .. 🙃
    this.props.disableBackDrop(true);

    // validate user inputs .. 🙃
    switch (name) {
      case "first_name": {
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({ isFirstNameValid: true, isFNInvalid: false, isEmpty: false, FNValidMessage: "" })
          : value.length > 0
          ? this.setState({ isFirstNameValid: false, isFNInvalid: true, isEmpty: false, FNValidMessage: "🤕 Only alphabet allowed" })
          : this.setState({ isFirstNameValid: false, isFNInvalid: true, isEmpty: true, FNValidMessage: "😑 Please enter first name" });
        break;
      }
      case "last_name": {
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({ isLastNameValid: true, isLNInvalid: false, isEmpty: false, LNValidMessage: "" })
          : value.length > 0
          ? this.setState({ isLastNameValid: false, isLNInvalid: true, isEmpty: false, LNValidMessage: "🤕 Only alphabet allowed" })
          : this.setState({ isLastNameValid: false, isLNInvalid: true, isEmpty: true, LNValidMessage: "😑 Please enter last name" });
        break;
      }
      case "dateOfBirth": {
        const currentDate = new Date();
        const userTypedDate = new Date(value);
        if (currentDate.getTime() > userTypedDate.getTime()) {
          this.setState({ isDOBValid: true, isDOBInvalid: false, isEmpty: false, DOBValidMessage: "" });
        } else {
          value.length > 0
            ? this.setState({ isDOBValid: false, isDOBInvalid: true, isEmpty: false, DOBValidMessage: "🤕 You must born." })
            : this.setState({ isDOBValid: false, isDOBInvalid: true, isEmpty: true, DOBValidMessage: "😑 should not be empty." });
        }
        break;
      }
      case "contact": {
        if (!isNaN(Number(value))) {
          value.length === 10
            ? this.setState({ isPhoneValid: true, isPhoneInvalid: false, isEmpty: false, phoneValidMessage: "" })
            : this.setState({ isPhoneValid: false, isPhoneInvalid: true, isEmpty: false, phoneValidMessage: "🤕 Phone Number must be of 10 digits" });
        } else {
          // value.length > 0?
          this.setState({ isPhoneValid: false, isPhoneInvalid: true, isEmpty: false, phoneValidMessage: "🤕 Only alphabet allowed" });
          // : this.setState({ isPhoneValid: false, isPhoneInvalid: true, isEmpty: true, phoneValidMessage: "😑 should not be empty." });
        }
        break;
      }
      case "city": {
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({ isCityNameValid: true, isCityInvalid: false, isEmpty: false, cityValidMessage: "" })
          : value.length > 0
          ? this.setState({ isCityNameValid: false, isCityInvalid: true, isEmpty: false, cityValidMessage: "🤕 Only alphabet allowed" })
          : this.setState({ isCityNameValid: false, isCityInvalid: true, isEmpty: true, cityValidMessage: "😑 should not be empty." });
        break;
      }
      case "inputState": {
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({ isStateValid: true, isStateInvalid: false, isEmpty: false, stateValidMessage: "" })
          : value.length > 0
          ? this.setState({ isStateValid: false, isStateInvalid: true, isEmpty: false, stateValidMessage: "🤕 Only alphabet allowed" })
          : this.setState({ isStateValid: false, isStateInvalid: true, isEmpty: true, stateValidMessage: "😑 should not be empty." });
        break;
      }
      case "country": {
        let regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({ isCountryValid: true, isCountryInvalid: false, isEmpty: false, countryValidMessage: "" })
          : value.length > 0
          ? this.setState({ isCountryValid: false, isCountryInvalid: true, isEmpty: false, countryValidMessage: "🤕 Only alphabet allowed" })
          : this.setState({ isCountryValid: false, isCountryInvalid: true, isEmpty: true, countryValidMessage: "😑 should not be empty." });
        break;
      }
      case "pinCode": {
        if (!isNaN(Number(value))) {
          value.length === 6
            ? this.setState({ isPINValid: true, isPINInvalid: false, isEmpty: false, PINValidMessage: "" })
            : this.setState({ isPINValid: false, isPINInvalid: true, isEmpty: false, PINValidMessage: "🤕 PIN must be of 6 digits" });
        } else {
          // value.length > 0?
          this.setState({ isPINValid: false, isPINInvalid: true, isEmpty: false, PINValidMessage: "🤕 Only alphabet allowed" });
          // : this.setState({ isPhoneValid: false, isPhoneInvalid: true, isEmpty: true, phoneValidMessage: "😑 should not be empty." });
        }
        break;
      }
      default:
        return;
    }
  };
  handleSubmit = async event => {
    const { first_name, headline, last_name, dateOfBirth, gender, contact, street, city, inputState, country, pinCode } = this.state;

    if (
      first_name === undefined ||
      last_name === undefined ||
      dateOfBirth === undefined ||
      contact === undefined ||
      city === undefined ||
      inputState === undefined ||
      country === undefined ||
      first_name === "" ||
      last_name === "" ||
      dateOfBirth === "" ||
      contact === "" ||
      city === "" ||
      inputState === "" ||
      country === ""
    ) {
      this.setState({ isEmpty: true, emptyMessage: "😑 Should not be empty." });
      return;
    }
    const data = {
      firstName: first_name,
      lastName: last_name,
      dob: dateOfBirth,
      gender: gender,
      headline: headline,
      phone: contact,
      address: {
        street: street,
        city: city,
        state: inputState,
        country: country,
        pin: pinCode
      }
    };
    let res = await fetch(API_URL + "/personal-info", {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: { at: localStorage.getItem("token") }
    });
    let response = await res.json();
    if (response.success) {
      toast.success("✔️ Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      this.props.onSave(response.data);
    } else {
      toast.error("🚫 Oops! unable to update. Check your connectivity.", { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };

  render() {
    return (
      <Container fluid>
        <div>
          <Form>
            <FormGroup>
              <Row>
                <Col md={6}>
                  <Label for="first_name">First Name*</Label>
                  <Input
                    invalid={this.state.first_name && this.state.first_name.length > 0 ? this.state.isFNInvalid : this.state.isEmpty}
                    valid={this.state.isFirstNameValid}
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isFirstNameValid}>{this.state.FNValidMessage || this.state.emptyMessage}</FormFeedback>
                </Col>
                <Col md={6}>
                  <Label for="last_name">Last Name*</Label>
                  <Input
                    invalid={this.state.last_name && this.state.last_name.length > 0 ? this.state.isLNInvalid : this.state.isEmpty}
                    valid={this.state.isLastNameValid}
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isLastNameValid}>{this.state.LNValidMessage || this.state.emptyMessage}</FormFeedback>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Label for="headline">Headline</Label>
              <Input
                type="text"
                id="headline"
                name="headline"
                placeholder="e.g: Junior Developer at TCS"
                value={this.state.headline}
                onChange={this.handleInputChange}
              />
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={6}>
                  <Label for="dob">Date of Birth*</Label>
                  <Input
                    invalid={this.state.dateOfBirth && this.state.dateOfBirth.length > 0 ? this.state.isDOBInvalid : this.state.isEmpty}
                    valid={this.state.isDOBValid}
                    type="date"
                    id="dob"
                    name="dateOfBirth"
                    value={this.state.dateOfBirth}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isDOBValid}>{this.state.DOBValidMessage || this.state.emptyMessage}</FormFeedback>
                </Col>
                <Col md={6}>
                  <legend>Gender*</legend>
                  <Row>
                    <Col sm={4}>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="gender" value="Male" checked={this.state.gender === "Male"} onChange={this.handleInputChange} />
                          {"Male"}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="gender" value="Female" checked={this.state.gender === "Female"} onChange={this.handleInputChange} />
                          {"Female"}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="gender" value="Others" checked={this.state.gender === "Others"} onChange={this.handleInputChange} />
                          {"Others"}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="contact_number">Phone</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">+91</InputGroupAddon>
                    <Input
                      invalid={this.state.isPhoneInvalid}
                      valid={this.state.isPhoneValid}
                      type="text"
                      id="contact_number"
                      name="contact"
                      value={this.state.contact}
                      onChange={this.handleInputChange}
                      required
                    />
                    <FormFeedback valid={this.state.isPhoneValid}>{this.state.phoneValidMessage}</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="current_email">Email*</Label>
                  <Input type="email" id="current_email" name="current_mail" value={this.state.mail} onChange={this.handleInputChange} disabled />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="street">Address</Label>
              <Input type="textarea" id="street" name="street" value={this.state.street} onChange={this.handleInputChange} />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="city">City*</Label>
                  <Input
                    invalid={this.state.city && this.state.city.length > 0 ? this.state.isCityInvalid : this.state.isEmpty}
                    valid={this.state.isCityValid}
                    type="text"
                    id="city"
                    name="city"
                    value={this.state.city}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isCityValid}>{this.state.cityValidMessage || this.state.emptyMessage}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="pinCode">PIN</Label>
                  <Input
                    invalid={this.state.isPINInvalid}
                    valid={this.state.isPINValid}
                    type="text"
                    id="pinCode"
                    name="pinCode"
                    value={this.state.pinCode}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isPINValid}>{this.state.PINValidMessage}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="state">State*</Label>
                  <Input
                    invalid={this.state.inputState && this.state.inputState.length > 0 ? this.state.isStateInvalid : this.state.isEmpty}
                    valid={this.state.isStateValid}
                    type="text"
                    id="state"
                    name="inputState"
                    value={this.state.inputState}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isStateValid}>{this.state.stateValidMessage || this.state.emptyMessage}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="country">Country*</Label>
                  <Input
                    invalid={this.state.country && this.state.country.length > 0 ? this.state.isCountryInvalid : this.state.isEmpty}
                    valid={this.state.isCountryValid}
                    type="text"
                    id="country"
                    name="country"
                    value={this.state.country}
                    onChange={this.handleInputChange}
                  />
                  <FormFeedback valid={this.state.isCountryValid}>{this.state.countryValidMessage || this.state.emptyMessage}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    );
  }
}

export default ManagePersonalInfo;
