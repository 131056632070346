import React, { Component } from "react";
import { viewComponent, deleteComponent } from "../services/APICall";
import { Badge, Modal, Card, CardBody, ModalHeader, ModalBody } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import ManageLanguageInfo from "../components/manage/ManageLanguagesInfo";
import { toast } from "react-toastify";
import BtnSpan from "../components/BtnSpan";
import { priBtnBgColor } from "../constants/Colors";
import { TwoLineLoader } from "../constants/Loader";
const $ = window.$;

export default class LanguageInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], showEdit: false, mode: "add", isHovering: false };
  }
  componentDidMount() {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }

    this.displayExistingData();
  }
  async displayExistingData() {
    let route = "/language";
    let responseViewData = await viewComponent(route);
    if (responseViewData !== null && responseViewData.success) {
      this.setState({ data: responseViewData.data, success: responseViewData.success });
    } else {
      this.setState({ data: [], success: false });
    }
  }
  editLanguageInfo = li => {
    this.setState({ mode: "edit", showEdit: true, selectedLanguageInfo: li });
  };
  addNewLanguageInfo = () => {
    this.setState({ mode: "add", showEdit: true, selectedLanguageInfo: undefined });
  };
  deleteLanguageInfo = async infoToBeDeleted => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const id = infoToBeDeleted._id;
            const route = "/language/";
            let responseDeleteData = await deleteComponent(route, id);
            if (responseDeleteData.success) {
              this.setState({ data: this.state.data.filter(Element => Element._id !== responseDeleteData.data._id) });
              toast.success("✔️ deleted successfully", { position: toast.POSITION.BOTTOM_RIGHT });
            } else {
              toast.info(`🛇delete unsuccessful.${responseDeleteData.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  save = response => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit
    }));
    let updateData = response;
    if (this.state.mode === "add") {
      this.setState({ data: [...this.state.data, updateData.data], success: updateData.success });
    } else {
      let index = this.state.data.findIndex(data => data._id === updateData.data._id);
      let data = this.state.data;
      data.splice(index, 1, updateData.data);

      this.setState({
        data: data,
        success: updateData.success
      });
    }
  };
  toggle = () => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit
    }));
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.addNewLanguageInfo} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-lang">
                  Languages Known
                </a>
              </h4>
            </div>

            <div id="collapse-lang" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <TwoLineLoader width={345} />
                    ) : this.state.data.length > 0 ? (
                      <div>
                        {this.state.data.map((x, key) => {
                          return (
                            <div
                              className={key === 0 ? "p-2" : "p-2 border-top"}
                              key={key}
                              onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                              onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                            >
                              <div className="float-right">
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.editLanguageInfo(x)}
                                  BtnSpanClass={"fas fa-pen mr-3"}
                                />
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.deleteLanguageInfo(x)}
                                  BtnSpanClass={"fas fa-trash"}
                                />
                              </div>
                              {x.languageName}
                              <p>
                                {x.read ? (
                                  <Badge pill style={{ backgroundColor: priBtnBgColor }} className="mr-2">
                                    Read
                                  </Badge>
                                ) : (
                                  ""
                                )}

                                {x.write ? (
                                  <Badge pill style={{ backgroundColor: priBtnBgColor }} className="mr-2">
                                    Write
                                  </Badge>
                                ) : (
                                  ""
                                )}

                                {x.speak ? (
                                  <Badge pill style={{ backgroundColor: priBtnBgColor }} className="mr-2">
                                    Speak
                                  </Badge>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          );
                        })}
                        {/* </Table> */}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add Languages you know.</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Modal isOpen={this.state.showEdit} toggle={this.toggle} backdrop="static">
                  <ModalHeader close={closeBtn} className="modalStyle">
                    Languages Known
                  </ModalHeader>
                  <ModalBody>
                    <ManageLanguageInfo onSave={this.save} languageInfo={this.state.selectedLanguageInfo} />
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
