import React, { Component } from "react";
import { Form, FormGroup, Input, Label, Button, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import { editComponent, addComponent } from "../../services/APICall";
import { priBtnBgColor } from "../../constants/Colors";

class ManageSkills extends Component {
  constructor(props) {
    super(props);
    this.state = { skillLevel: "beginner" };
  }
  componentDidMount = async => {
    if (this.props.selectedSkill !== undefined) {
      this.setState({
        skillName: this.props.selectedSkill.skillName,
        skillLevel: this.props.selectedSkill.skillLevel,
        skillId: this.props.selectedSkill._id,
        isEdit: true
      });
    } else {
      this.setState({
        isEdit: false
      });
    }
  };

  handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });

    switch (name) {
      case "skillName":
        {
          const regExp = /.*\S.*/;
          regExp.test(value)
            ? this.setState({
                skillNameValid: true,
                isNameInValid: false,
                isEmpty: false,
                skillValidMessage: "👍 Valid name"
              })
            : value.length > 0
            ? this.setState({ skillNameValid: false, isNameInValid: true, isEmpty: false, skillValidMessage: "Enter a valid Name..!" })
            : this.setState({ skillNameValid: false, isNameInValid: true, isEmpty: true, skillValidMessage: "Cannot not be empty..!" });
        }
        break;
      default: {
      }
    }
  };
  handleSubmit = async event => {
    event.preventDefault();
    const { skillName } = this.state;
    if (skillName === undefined || skillName.trim() === "") {
      this.setState({ isEmpty: true, message: "Please fill the above field..!" });
      return;
    }
    if (this.state.isNameInValid === true) {
      this.setState({ isNameInValid: true, skillValidMessage: "Please enter a valid name..!" });
      return;
    }

    const skill = {
      skillName: this.state.skillName.trim(),
      skillLevel: this.state.skillLevel
    };

    if (this.state.isEdit === false) {
      let response = await addComponent("/skills", skill);
      if (response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success("✔️ Added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to save.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await editComponent("/skills/", this.state.skillId, skill);
      if (response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success("✔️ Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="skillName">Skill Name*</Label>
            <Input
              type="text"
              id="skillName"
              name="skillName"
              value={this.state.skillName}
              onChange={this.handleChange}
              placeholder="What skill you good at?"
              invalid={this.state.skillName && this.state.skillName.length > 0 ? this.state.isNameInValid : this.state.isEmpty}
              valid={this.state.skillNameValid}
              required
            />
            <FormFeedback>{this.state.skillValidMessage || this.state.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="skillLevel">Level*</Label>
            <Input type="select" name="skillLevel" value={this.state.skillLevel} onChange={this.handleChange}>
              <option>beginner</option>
              <option>intermediate</option>
              <option>expert</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Button type="submit" className="btn-block" style={{ backgroundColor: priBtnBgColor, border: "none" }}>
              Save
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default ManageSkills;
