import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Templates from "./view/Templates";
import LoadingPage from "./components/LoadingPage";
import { parseJwt } from "./constants/Constant";
import ErrorPage from "./view/ErrorPage";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import RegistrationCard from "./components/RegistrationCard";
import RegistrationPending from "./pages/RegistrationPending";
import SuccessPage from "./pages/SuccessPage";

const AuthRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  let isAuthenticated = false;

  if (token !== null) {
    var decodedToken = parseJwt(token);
    var currentTime = new Date().getTime() / 1000;
    if (decodedToken.exp === undefined || decodedToken.exp > currentTime) {
      isAuthenticated = true;
    } else {
      localStorage.clear();
    }
  }
  return <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} exact />
          <Route path="/templates" component={Templates} exact />
          <Route path="/register" component={RegistrationCard} />
          <Route path="/registration-pending" component={RegistrationPending} />
          <Route path="/registration-success" component={SuccessPage} />
          <Route path="/stuworx-resume" component={LoadingPage} />
          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <AuthRoute path="/" component={HomePage} exact />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
