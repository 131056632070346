import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Moment from "moment";
import ManageWorkExperience from "../components/manage/ManageWorkExperience";
import { toast } from "react-toastify";
import { deleteComponent, viewComponent } from "../services/APICall";
import BtnSpan from "../components/BtnSpan";
import { Loader } from "../constants/Loader";
const $ = window.$;
class WorkExperience extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = { showModal: false, experienceDetails: [], isHovering: false };
  }
  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }
    let response = await viewComponent("/experience-details");
    if (response !== null && response.success) {
      this.setState({ experienceDetails: response.data, success: response.success });
    } else {
      this.setState({ experienceDetails: [], success: false });
    }
  };
  handleAdd = async () => {
    this.setState({ selectedExperienceDetail: undefined, showModal: true });
  };
  handleEdit = async object => {
    this.setState({ selectedExperienceDetail: object, showModal: true });
  };
  handleDelete = async workToDelete => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/experience-details/", workToDelete._id);
            if (response.success) {
              let workObj = this.state.experienceDetails.filter(data => data !== workToDelete);
              this.setState({ experienceDetails: workObj });
              toast.success(`️️️✔️ removed successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newWork, isAdd, oldWork) => {
    let { experienceDetails } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ experienceDetails: [...experienceDetails, newWork] });
    } else {
      let temp = experienceDetails;
      let index = temp.findIndex(obj => obj._id === oldWork._id);
      temp.splice(index, 1, newWork);
      this.setState({ experienceDetails: temp });
    }
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div className="panel-group">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 className="panel-title">
              <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
              <a id="link" data-toggle="collapse" href="#collapse-work">
                Work Experience
              </a>
            </h4>
          </div>
          <div id="collapse-work" className={this.state.collapse}>
            <div className="panel-body">
              <Card>
                <CardBody>
                  {this.state.success === undefined ? (
                    <div>
                      <Loader width={490} />
                    </div>
                  ) : this.state.experienceDetails.length > 0 ? (
                    <div>
                      {this.state.experienceDetails.map((currentDetail, key) => {
                        return (
                          <div
                            key={key}
                            className={key === 0 ? "p-2 py-4" : "p-2 py-4 border-top"}
                            onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                            onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                          >
                            <span className="float-right">
                              <BtnSpan
                                isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                onClickFunction={() => this.handleEdit(currentDetail)}
                                BtnSpanClass={"fas fa-pen mr-3"}
                              />
                              <BtnSpan
                                isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                onClickFunction={() => this.handleDelete(currentDetail)}
                                BtnSpanClass={"fas fa-trash"}
                              />
                            </span>
                            <div className="h5">{currentDetail.jobTitle}</div>
                            <h6>{currentDetail.companyName}</h6>
                            <div className="text-muted">
                              <p>
                                {Moment(currentDetail.startDate).format("MMM Do YYYY")} -{" "}
                                {currentDetail.currentlyWorking ? "Present" : Moment(currentDetail.endDate).format("MMM Do YYYY")}
                                <br />
                                {currentDetail.location}
                              </p>
                            </div>

                            <p dangerouslySetInnerHTML={{ __html: currentDetail.summary && currentDetail.summary }} />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <h6>
                          <i className="far fa-frown" />
                          &nbsp; Sorry! couldn't find any details
                        </h6>
                      </div>
                      <p className="text-muted">Please add your work experience details...!</p>
                    </div>
                  )}
                </CardBody>
              </Card>
              <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static" size="lg">
                <ModalHeader close={closeBtn} className="modalStyle">
                  Work Experience
                </ModalHeader>
                <ModalBody className="modalScroll">
                  <ManageWorkExperience
                    ref={this.child}
                    onSubmit={true}
                    onSave={this.save}
                    onClose={this.toggle}
                    selectedExperienceDetail={this.state.selectedExperienceDetail}
                  />
                </ModalBody>
                <ModalFooter>
                  <div className="float-right">
                    <Button type="button" id="cnclBtn" className="btn mr-2 px-4" onClick={this.toggle}>
                      Cancel
                    </Button>
                    <Button className="btn  px-4" id="saveBtn" onClick={() => this.child.current.handleSubmit()}>
                      Save
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WorkExperience;
