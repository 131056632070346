// Button Background color
export const priBtnBgColor = "#5F5DA2";
export const secBtnBgColor = "#DEDEDE";

// Button text color
export const priBtnColor = "#FFF";
export const cancelBtnColor = "#D9586D";
export const closeBtnColor = "#FFF";
// modal color
export const modalHeaderBG = "#EDEDED";

// background color
export const BGColor = "#5F5DA2";

// text color
export const priTextColor = "#FDFDFE";

// body background color
export const bodyBgColor = "#F2F2F2";

// button background hover color
export const priBtnHoverBgColor = "#4e4c9b";
export const deleteBtnHoverBgColor = "D9586D";
