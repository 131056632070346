import React from "react";
import { Row, Col, Card, CardBody, CardFooter } from "reactstrap";

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center text-center min-vh-100">
        <div className="custom-price-width">
          <Row className="custom-price">
            <Col className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-2">
              <Card className="shadow h-100">
                {/* <CardHeader>
                <h2>free</h2>
              </CardHeader> */}
                <CardBody>
                  <img src="https://img.icons8.com/dusk/128/000000/one-free.png" alt="free" />
                  <div className="border-top mt-3 text-left pl-2">
                    <div>
                      <h4 className="mt-3">
                        <i class="fas fa-check-double custom-tick-color" /> Unlimited Resumes
                      </h4>
                      <h4>
                        <i class="fas fa-check-double custom-tick-color" /> Only One Template
                      </h4>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <h3> &#8377;0</h3>
                </CardFooter>
              </Card>
            </Col>
            <Col className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-2">
              <Card className="shadow h-100">
                {/* <CardHeader>
                <h2>Paid</h2>
            </CardHeader> */}
                <CardBody>
                  <img src="https://img.icons8.com/dusk/128/000000/money-box.png" alt="paid" />
                  <div className="border-top mt-3 text-left pl-2">
                    <h4 className="mt-3">
                      <i class="fas fa-check-double custom-tick-color" /> Unlimited Resumes
                    </h4>
                    <h4>
                      <i class="fas fa-check-double custom-tick-color" /> Unlimited Templates
                    </h4>
                    <h4>
                      <i class="fas fa-check-double custom-tick-color" /> One Time Subscription
                    </h4>
                    <h4>
                      <i class="fas fa-check-double custom-tick-color" /> Life Time Validity
                    </h4>
                    {/* <h4>
                    <i class="fas fa-check-double custom-tick-color" /> &#8377; 999
                  </h4> */}
                  </div>
                </CardBody>
                <CardFooter>
                  <h3> &#8377;50</h3>
                </CardFooter>
              </Card>
              <div className="ribbon custom-ribbon ">
                <span>
                  <>
                    <i className="fas fa-crown" />
                    &nbsp;Premium
                  </>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Price;
