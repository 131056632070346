import React, { Component } from "react";
import RichTextEditor from "react-rte";

const toolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [{ label: "Bold", style: "BOLD", className: "custom-css-class" }, { label: "Italic", style: "ITALIC" }],

  BLOCK_TYPE_BUTTONS: [{ label: "UL", style: "unordered-list-item" }, { label: "OL", style: "ordered-list-item" }]
};

class RTETextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createValueFromString(this.props.existingSummary !== undefined ? this.props.existingSummary : "", "html")
    };
  }
  onChange = value => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <div>
        <RichTextEditor value={this.state.value && this.state.value} onChange={this.onChange} toolbarConfig={toolbarConfig} placeholder="give some details" />
      </div>
    );
  }
}

export default RTETextEditor;
