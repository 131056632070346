import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Row, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { addComponent, editComponent } from "../../services/APICall";
import { toast } from "react-toastify";
import { priBtnBgColor } from "../../constants/Colors";
class ManageLanguageInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { write: false, read: false, speak: false, validInput: undefined, validateMessage: undefined };
  }
  componentDidMount = async () => {
    if (this.props.languageInfo !== undefined) {
      this.setState({
        languageName: this.props.languageInfo.languageName,
        read: this.props.languageInfo.read,
        write: this.props.languageInfo.write,
        speak: this.props.languageInfo.speak,

        id: this.props.languageInfo._id,
        edit: true
      });
    } else {
      this.setState({ edit: false });
    }
  };
  handleCheck = event => {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState({ [name]: value });
    if (value === true) {
      this.setState({ validCheck: true });
    }
  };
  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
    if (name === "languageName") {
      const regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
      regExp.test(value.trim())
        ? this.setState({ languageNameValid: true, isLangInvalid: false, isEmpty: false, langValidMessage: "" })
        : value.length > 0
        ? this.setState({ languageNameValid: false, isLangInvalid: true, isEmpty: false, langValidMessage: "Invalid entry use only alphabets." })
        : this.setState({ languageNameValid: false, isLangInvalid: true, isEmpty: true, langValidMessage: "Should not be empty" });
    }
  };
  handleSubmit = async event => {
    event.preventDefault();
    let emptyInput,
      allUnchecked = true;
    if (this.state.languageName === undefined || this.state.languageName === "") {
      this.setState({ isEmpty: true, langValidMessage: "Please fill the details" });
      return;
    }
    if (this.state.read || this.state.speak || this.state.write) {
      this.setState({ validCheck: true });
      allUnchecked = false;
    } else {
      this.setState({ validateCheckMessage: "Select atleast one", validCheck: false });
    }

    if (allUnchecked || emptyInput) {
      return;
    }

    let languageInfo = {
      languageName: this.state.languageName,
      read: this.state.read,
      write: this.state.write,
      speak: this.state.speak
    };
    if (this.state.edit !== true) {
      let route = "/language";
      let addRes = await addComponent(route, languageInfo);
      if (addRes.success) {
        this.props.onSave(addRes);
        toast.success("✔️ Added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🛇details cant be added.${addRes.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let route = "/language/";
      let id = this.state.id;
      let editRes = await editComponent(route, id, languageInfo);
      if (editRes.success) {
        this.props.onSave(editRes);
        toast.success("✔️ edited successfully", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🛇details cant be edited.${editRes.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };
  render() {
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  valid={this.state.languageNameValid}
                  invalid={this.state.languageName && this.state.languageName.length > 0 ? this.state.isLangInvalid : this.state.isEmpty}
                  type="text"
                  onChange={this.handleChange}
                  name="languageName"
                  value={this.state.languageName}
                  placeholder="Enter the language you know"
                  required
                />
                <FormFeedback>{this.state.langValidMessage || this.validInput}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="m-2">
            <Col sm="3" md="3">
              <FormGroup check>
                <Input
                  invalid={!this.state.validCheck}
                  type="checkbox"
                  id="read"
                  onChange={this.handleCheck}
                  checked={this.state.read === true ? true : false}
                  name="read"
                />
                <Label for="read">Read</Label>
              </FormGroup>
            </Col>
            <Col sm="3" md="3">
              <FormGroup check>
                <Input
                  invalid={!this.state.validCheck}
                  type="checkbox"
                  id="write"
                  onChange={this.handleCheck}
                  checked={this.state.write === true ? true : false}
                  name="write"
                />
                <Label for="write">Write</Label>
              </FormGroup>
            </Col>
            <Col sm="3" md="3">
              <FormGroup check>
                <Input
                  invalid={!this.state.validCheck}
                  type="checkbox"
                  id="speak"
                  onChange={this.handleCheck}
                  checked={this.state.speak === true ? true : false}
                  name="speak"
                />
                <Label for="speak">Speak</Label>
                <FormFeedback>{this.state.validateCheckMessage}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" className="btn-block  px-4" style={{ backgroundColor: priBtnBgColor, border: "none" }}>
            Save
          </Button>
        </Form>
      </div>
    );
  }
}
export default ManageLanguageInfo;
