import { httpClient } from "./commonServices";
import { API_URL } from "../config";

export async function generateCashFreeCheckSumToken(data) {
    let response = await httpClient(API_URL + "/generate-cashfree-checksum", "POST", data);
    return response;
  }

  
export async function CheckCashFreeResponse(data) {
    let response = await httpClient(API_URL + "/check-cashfree-response", "POST", data);
    return response;
  }

  export async function GeneratePayUCheckSum(data) {
    let response = await httpClient(API_URL + "/generate-payu-checksum", "POST", data);
    return response;
  }

  export async function SaveInstamojoResponse(data) {
    let response = await httpClient(API_URL + "/save-instamojo-response", "POST", data);
    return response;
  }

  export async function SavePayUResponse(data) {
    let response = await httpClient(API_URL + "/check-payu-response", "POST", data);
    return response;
  }