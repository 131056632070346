import React, { Component } from "react";
import { viewComponent } from "../services/APICall";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import ManageAbout from "../components/manage/ManageAbout";
import BtnSpan from "../components/BtnSpan";
import { AboutLoader } from "../constants/Loader";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { about: undefined, success: undefined, tooltipOpen: false };
  }

  componentDidMount = async () => {
    const response = await viewComponent("/about-user");
    if (response !== null && response.success === true && response.data.about !== undefined) {
      this.setState({ about: response.data.about, success: true });
    } else {
      this.setState({ success: false, isEdit: false });
    }
  };

  render() {
    return (
      <>
        <div className="h4">
          About
          <span className="editbtnAbt">
            <BtnSpan isHovering={true} onClickFunction={this.handleEdit} BtnSpanClass={"fas fa-pen float-right mr-3"} />
          </span>
        </div>
        <Card>
          <CardBody>
            {this.state.success === undefined ? (
              <div>
                <AboutLoader width={345} />
              </div>
            ) : (
              <div>
                {this.state.isEdit === true ? (
                  <ManageAbout saveChanges={this.saveChanges} value={this.state.success === true ? this.state.about : undefined} cancelEdit={this.cancelEdit} />
                ) : (
                  <Container>
                    <Row>
                      <Col>
                        <p>{this.state.success === false ? "Write about yourself in not more than 150 characters" : this.state.about}</p>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </>
    );
  }

  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  saveChanges = about => {
    this.setState({ isEdit: false, about: about, success: true });
  };

  cancelEdit = () => {
    this.setState({ isEdit: false });
  };
}

export default About;
