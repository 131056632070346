import React, { Component } from "react";
import { parseJwt } from "../constants/Constant";
import { userAuthentication } from "../services/APICall";
import { HomePageLoader } from "../constants/Loader";

class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    console.log(this.props);
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);

    let tokenFromParams = query.get("token");

    let decodedToken = await parseJwt(tokenFromParams);
    let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);

    if (apiResponse && apiResponse.success) {
      localStorage.setItem("token", apiResponse.data.token);
      //   this.props.history.push("/");
    } else {
      alert(`${apiResponse.message}`);
    }
  };

  render() {
    return (
      <div className="loadingPage">
        <div className="loadingSpinner">
          <HomePageLoader />
        </div>
        <br />
        <div>Loading</div>
        <br />
        <p>Please wait, while we redirect to your homepage</p>
      </div>
    );
  }
}

export default LoadingPage;
