import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, ModalBody, ModalHeader, ModalFooter, CardBody, Modal, Button, CardText } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ManageEducationalInfo from "../components/manage/ManageEducationalInfo";
import Moment from "moment";
import { toast } from "react-toastify";
import { viewComponent, deleteComponent } from "../services/APICall";
import BtnSpan from "../components/BtnSpan";
import { Loader } from "../constants/Loader";
const $ = window.$;
class EducationalPage extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = { data: [], mode: "add", showEdit: false, collapse: "", isHovering: false };
  }

  componentDidMount() {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }

    this.displayExistingData();
  }

  async displayExistingData() {
    let route = "/educational-details";
    let responseViewData = await viewComponent(route);
    if (responseViewData !== null && responseViewData.success) {
      this.setState({ data: responseViewData.data, success: responseViewData.success });
    } else {
      this.setState({ data: [], success: false });
    }
  }

  addNewEducationalInfo = async () => {
    this.setState({ mode: "add", showEdit: true, selectedEducationalInfo: undefined });
  };
  deleteEducationalInfo = async infoToBeDeleted => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const id = infoToBeDeleted._id;
            const route = "/educational-details/";
            let responseDeleteData = await deleteComponent(route, id);
            if (responseDeleteData.success) {
              this.setState({ data: this.state.data.filter(Element => Element._id !== responseDeleteData.data._id) });
              toast.success("✔️ deleted successfully", { position: toast.POSITION.BOTTOM_RIGHT });
            } else {
              toast.info(`🛇delete unsuccessful.${responseDeleteData.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  editEducationalInfo = ei => {
    this.setState({ mode: "edit", showEdit: true, selectedEducationalInfo: ei });
  };
  save = response => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit
    }));
    let updateData = response;
    if (this.state.mode === "add") {
      this.setState({ data: [...this.state.data, updateData.data], success: updateData.success });
    } else {
      let index = this.state.data.findIndex(data => data._id === updateData.data._id);
      let data = this.state.data;
      data.splice(index, 1, updateData.data);

      this.setState({
        data: data,
        success: updateData.success
      });
    }
  };
  // handle button hover
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      showEdit: !prevState.showEdit
    }));
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.addNewEducationalInfo} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-edu">
                  Education
                </a>
              </h4>
            </div>

            <div id="collapse-edu" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <Loader width={490} />
                      </div>
                    ) : this.state.data.length > 0 ? (
                      <div>
                        {this.state.data.map((x, key) => {
                          return (
                            <div
                              key={key}
                              className={key === 0 ? "p-2 py-4" : "p-2 py-4 border-top"}
                              onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                              onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                            >
                              <span id="eduFontSize" className="float-right">
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.editEducationalInfo(x)}
                                  BtnSpanClass={"fas fa-pen mr-3"}
                                />
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.deleteEducationalInfo(x)}
                                  BtnSpanClass={"fas fa-trash"}
                                />
                              </span>
                              <CardText className="h5">
                                {x.degree}
                                {x.stream !== undefined ? " in " + x.stream : ""}
                              </CardText>
                              <CardText>{x.instituteName}</CardText>
                              From {Moment(x.startDate).format("YYYY")} To {x.currentlyStudying ? "Present" : Moment(x.endDate).format("YYYY")}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add your educational details...!</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>

              <Modal isOpen={this.state.showEdit} toggle={this.toggle} backdrop="static" size="lg">
                <ModalHeader close={closeBtn} className="modalStyle">
                  Educational details
                </ModalHeader>
                <ModalBody className="modalScroll">
                  <ManageEducationalInfo
                    ref={this.child}
                    onSubmit={true}
                    onSave={this.save}
                    onClose={this.toggle}
                    EducationDetails={this.state.selectedEducationalInfo}
                  />
                </ModalBody>
                <ModalFooter>
                  <div className="float-right">
                    <Button type="button" id="cnclBtn" className="btn mr-2 px-4" onClick={this.toggle}>
                      Cancel
                    </Button>
                    <Button className="btn  px-4" id="saveBtn" onClick={() => this.child.current.handleSubmit()}>
                      Save
                    </Button>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EducationalPage;
