import React, { Component } from "react";
import { Row, Col, Container, Button, Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import WorkExperience from "../view/WorkExperience";
import LanguageInfo from "../view/LanguageInfo";
import Website from "../view/Website";
import EducationalPage from "../view/EducationalPage";
import SkillInfo from "../view/SkillInfo";
import CertificationInfo from "../view/CertificationInfo";
import InterestTagsInput from "../view/InterestTagsInput";
import About from "../view/About";
import { API_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import Profile from "../view/Profile";
import SkillToChoose from "../view/createResume/SkillToChoose";
import Project from "../view/Project";
import SelectEducationalInfo from "../view/createResume/SelectEducationalInfo";
import SelectWorkExperience from "../view/createResume/SelectWorkExperience";
import SelectWebsites from "../view/createResume/SelectWebsites";
import SelectCertificate from "../view/createResume/SelectCertificate";
import StepZilla from "react-stepzilla";
import { withRouter } from "react-router-dom";
import "react-stepzilla/src/css/main.css";
import SelectTemplate from "../view/createResume/SelectTemplate";
import { viewComponent, deleteComponent } from "../services/APICall";
import BtnSpan from "../components/BtnSpan";
import { confirmAlert } from "react-confirm-alert";
import SelectProject from "../view/createResume/SelectProject";
import ProfessionalSummary from "../view/ProfessionalSummary";
import SelectResumeTitle from "../view/createResume/SelectResumeTitle";
const $ = window.$;
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      steps: false
    };
  }
  componentDidMount = async () => {
    let response = await viewComponent("/resumes");
    if (response && response.success === true) {
      this.setState({ resumes: response.data, success: true });
    } else {
      this.setState({ resumes: [], success: false });
    }
    if ($(window).width() >= 768) {
      this.setState({ stepper: true });
    } else {
      this.setState({ stepper: false });
    }
  };
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newResume, isAdd, oldResume) => {
    let { resumes } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ resumes: [...resumes, newResume] });
    } else {
      let temp = resumes;
      let index = temp.findIndex(obj => obj._id === oldResume.resumeId);
      temp.splice(index, 1, newResume);
      this.setState({ resumes: temp });
    }
  };
  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/login");
  };
  handleResume = () => {
    this.setState({ showModal: true, editResume: undefined, steps: false });
  };
  getTitle = titleValue => {
    this.setState({ title: titleValue });
  };
  getSkill = skillValue => {
    this.setState({ skills: skillValue });
  };
  getEducation = educationValue => {
    this.setState({ educations: educationValue });
  };
  getWork = workValue => {
    this.setState({ experiences: workValue });
  };
  getProject = projectValue => {
    this.setState({ projects: projectValue });
  };
  getWebsite = websiteValue => {
    this.setState({ websites: websiteValue });
  };
  getCertification = certificationValue => {
    this.setState({ certificates: certificationValue });
  };
  getTemplate = (title, template) => {
    this.setState({ title: title, template: template });
  };
  handleDownload = async resumeTitle => {
    let res = await fetch(`${API_URL}/resume/${resumeTitle}`, {
      method: "GET",
      responseType: "blob",
      headers: { at: localStorage.getItem("token") }
    });
    let pdf = await res.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = pdfFileURL;
    a.download = resumeTitle + ".pdf";
    a.click();
  };
  showTemplates = async => {
    this.props.history.push("/templates");
  };
  handleDelete = async resume => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/resumes/", resume._id);
            if (response && response.success) {
              let resumeObj = this.state.resumes.filter(data => data !== resume);
              this.setState({ resumes: resumeObj });
              toast.success(`️️️✔️ ${resume.title} Resume deleted successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };

  handleEdit = resume => {
    this.setState({ showModal: true, editResume: resume, steps: true });
  };

  render() {
    let selectedDetails = {
      title: this.state.title,
      skills: this.state.skills,
      educations: this.state.educations,
      experiences: this.state.experiences,
      certificates: this.state.certificates,
      projects: this.state.projects,
      websites: this.state.websites
    };
    const steps = [
      { name: "Title", component: <SelectResumeTitle edited={this.state.editResume && this.state.editResume.title} onGetTitle={this.getTitle} /> },
      { name: "Skill", component: <SkillToChoose edited={this.state.editResume && this.state.editResume.skills} onGetSkill={this.getSkill} /> },
      {
        name: "Education",
        component: <SelectEducationalInfo edited={this.state.editResume && this.state.editResume.educations} onGetEducation={this.getEducation} />
      },
      { name: "Experience", component: <SelectWorkExperience edited={this.state.editResume && this.state.editResume.experiences} onGetWork={this.getWork} /> },
      { name: "Website", component: <SelectWebsites edited={this.state.editResume && this.state.editResume.websites} onGetWebsite={this.getWebsite} /> },
      { name: "Project", component: <SelectProject edited={this.state.editResume && this.state.editResume.projects} onGetProject={this.getProject} /> },
      {
        name: "Certificate",
        component: <SelectCertificate edited={this.state.editResume && this.state.editResume.certificates} onGetCertification={this.getCertification} />
      },
      {
        name: "Template",
        component: (
          <SelectTemplate
            isEdit={this.state.editResume && this.state.editResume}
            onSave={this.save}
            details={selectedDetails}
            originalResumes={this.state.resumes}
          />
        )
      }
    ];
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div className="homepage">
        <div>
          <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white">
            <a className="navbar-brand" target="__blank" href="https://stuworx.com">
              <img
                id="logo"
                src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype.png"
                className="logo logotype-negative hide"
                alt="STUWORX"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto ">
                <li className="nav-item dropdown p-1">
                  <Button className="dropdown-toggle btn-block" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Resumes
                  </Button>
                  <div className="dropdown-menu " id="dropdownBox" aria-labelledby="navbarDropdownMenuLink">
                    <div>
                      {this.state.success === undefined ? (
                        <Spinner color="primary" />
                      ) : this.state.resumes.length > 0 ? (
                        this.state.resumes.map((currentResume, index) => {
                          return (
                            <div key={index}>
                              <div className="dropdown-item" id="dropdown-item">
                                <span className="float-right">
                                  <BtnSpan isHovering={true} onClickFunction={() => this.handleEdit(currentResume)} BtnSpanClass={"fas fa-pen mr-3"} />
                                  <BtnSpan isHovering={true} onClickFunction={() => this.handleDelete(currentResume)} BtnSpanClass={"fas fa-trash mr-3"} />
                                  <BtnSpan
                                    isHovering={true}
                                    className="btn btn-sm"
                                    id="downloadButton"
                                    onClickFunction={() => this.handleDownload(currentResume.title)}
                                    BtnSpanClass={"fas fa-download mr-0"}
                                  />
                                </span>
                                {currentResume.title}
                              </div>
                              <div className="dropdown-divider" />
                            </div>
                          );
                        })
                      ) : (
                        ""
                      )}
                    </div>
                    <Button className="btn btn-block dropdown-item text-center" onClick={this.handleResume}>
                      <i className="fas fa-plus createNewButton" />
                      &nbsp;&nbsp; Create Resume
                    </Button>
                  </div>
                </li>
                <li className="nav-link-active  p-1">
                  <Button id="btn-logout" className="btn btn-block text-center" onClick={this.showTemplates}>
                    Templates
                  </Button>
                </li>
                <li className="nav-item p-1">
                  <Button className="btn btn-block" id="btn-logout" onClick={this.handleLogout}>
                    <i className="fas fa-sign-out-alt" />
                    &nbsp;&nbsp; Logout
                  </Button>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <Container>
          <Row py={4}>
            <Col md={5} lg={4} sm={12} xs={12}>
              <Row>
                <Col className="mt-3">
                  <Profile />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <About />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <InterestTagsInput />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <SkillInfo />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <LanguageInfo />
                </Col>
              </Row>
              <hr />
            </Col>

            <Col md={7} lg={8} sm={12} xs={12}>
              <Row className="md-mt-5">
                <Col>
                  <ProfessionalSummary />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <WorkExperience />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <EducationalPage />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Project />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <CertificationInfo />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Website />
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer hideProgressBar={true} />
        </Container>
        <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static" size="xl">
          <ModalHeader close={closeBtn} className="modalStyle">
            {this.state.steps ? "Edit Resume" : "Create Resume"}
          </ModalHeader>
          <ModalBody>
            <div className="step-progress ">
              <StepZilla
                steps={steps}
                showNavigation={true}
                stepsNavigation={this.state.steps}
                preventEnterSubmission={true}
                dontValidate={false}
                prevBtnOnLastStep={true}
                showSteps={this.state.stepper}
                backButtonCls="btn btn-next float-left "
                nextButtonCls="btn btn-prev float-right"
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default withRouter(HomePage);
