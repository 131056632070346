import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, FormFeedback, InputGroup, Form, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import { editComponent, addComponent } from "../../services/APICall";
import { toast } from "react-toastify";

export default class ManageEducationalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { currentlyStudying: false, instituteNameError: "", instituteName: "", degree: "", stream: "", location: "", startDate: "", endDate: "" };
  }
  componentDidMount = async () => {
    if (this.props.EducationDetails !== undefined) {
      let startDate = moment(this.props.EducationDetails.startDate).format("YYYY-MM-DD");
      let endDate = moment(this.props.EducationDetails.endDate).format("YYYY-MM-DD");
      this.setState({
        instituteName: this.props.EducationDetails.instituteName,
        location: this.props.EducationDetails.location,
        degree: this.props.EducationDetails.degree,
        stream: this.props.EducationDetails.stream,
        // percentage: this.props.EducationDetails.percentage,
        startDate: startDate,
        endDate: endDate,
        currentlyStudying: this.props.EducationDetails.currentlyStudying,
        educationDetailsId: this.props.EducationDetails._id,
        edit: true
      });
    } else {
      this.setState({ edit: false });
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
    if (name === "currentlyStudying" && this.state.currentlyStudying === false) {
      this.setState({ currentlyStudying: true, endDate: undefined });
    } else {
      this.setState({ currentlyStudying: false });
    }

    switch (name) {
      case "instituteName": {
        const regExp = /.*\S.*/;
        regExp.test(value.trim())
          ? this.setState({ instituteNameValid: true, isInstInvalid: false, isEmpty: false, instValidMessage: "" })
          : value.length > 0
          ? this.setState({ instituteNameValid: false, isInstInvalid: true, isEmpty: false, instValidMessage: "*invalid name" })
          : this.setState({ instituteNameValid: false, isInstInvalid: true, isEmpty: true, instValidMessage: "*should not be empty" });
        break;
      }
      case "location": {
        const regExp = /^[ A-Za-z0-9_,@./#&+-]*$/;
        regExp.test(value.trim())
          ? this.setState({ locationNameValid: true, isLocInvalid: false, isEmpty: false, locValidMessage: "" })
          : value.length > 0
          ? this.setState({ locationNameValid: false, isLocInvalid: true, isEmpty: false, locValidMessage: "Invalid entry use only alphabets." })
          : this.setState({ locationNameValid: false, isLocInvalid: true, isEmpty: true, locValidMessage: "Should not be empty" });
        break;
      }
      case "degree": {
        const regExp = /^[A-Z @~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i;
        regExp.test(value.trim())
          ? this.setState({ degreeValid: true, isDegInvalid: false, isEmpty: false, degValidMessage: "" })
          : value.length > 0
          ? this.setState({ degreeValid: false, isDegInvalid: true, isEmpty: false, degValidMessage: "Invalid entry use only alphabets." })
          : this.setState({ degreeValid: false, isDegInvalid: true, isEmpty: true, degValidMessage: "Should not be empty" });
        break;
      }
      case "percentage": {
        if (value > 0 && value <= 100) {
          this.setState({ percentageValid: true, isPercentageInvalid: false, isEmpty: false, percentageValidMessage: "" });
        } else {
          // value.length > 0
          //   ?
          this.setState({
            percentageValid: false,
            isPercentageInvalid: true,
            isEmpty: false,
            percentageValidMessage: "* percentage should be greater 0 and less than 100"
          });
          // : this.setState({ percentageValid: false, isPercentageInvalid: true, isEmpty: true, percentageValidMessage: "Should not be empty" });
        }
        break;
      }
      case "startDate": {
        const date = new Date().getTime();
        const currentDate = new Date(value).getTime();
        const currentVal = currentDate;
        if (currentVal < date) {
          this.setState({ startDateValid: true, isStartDateInvalid: false, isEmpty: false, startDateValidMessage: "" });
        } else {
          value.length > 0
            ? this.setState({
                startDateValid: false,
                isStartDateInvalid: true,
                isEmpty: false,
                startDateValidMessage: "select date correctly"
              })
            : this.setState({ startDateValid: false, isStartDateInvalid: true, isEmpty: true, startDateValidMessage: "Should not be empty" });
        }

        break;
      }

      case "endDate": {
        const startDate = new Date(this.state.startDate).getTime();
        const currentDate = new Date(value).getTime();
        const currentVal = currentDate;
        if (currentVal > startDate) {
          this.setState({ endDateValid: true, isEndDateInvalid: false, isEmpty: false, endDateValidMessage: "" });
        } else {
          value.length > 0
            ? this.setState({
                endDateValid: false,
                isEndDateInvalid: true,
                isEmpty: false,
                endDateValidMessage: "select date correctly"
              })
            : this.setState({ endDateValid: false, isEndDateInvalid: true, isEmpty: true, endDateValidMessage: "Should not be empty" });
        }

        break;
      }
      case "currentlyStudying": {
        this.setState({ endDateValid: true, isEndDateInvalid: false, isEmpty: false, endDateValidMessage: "" });
        break;
      }
      default: {
      }
    }
  };

  handleSubmit = async event => {
    const { instituteName, location, degree, startDate, endDate, currentlyStudying } = this.state;
    if (
      instituteName === undefined ||
      location === undefined ||
      degree === undefined ||
      // percentage === undefined ||
      startDate === undefined ||
      currentlyStudying === undefined ||
      instituteName === "" ||
      location === "" ||
      degree === "" ||
      // percentage === "" ||
      startDate === ""
    ) {
      this.setState({ isEmpty: true, message: "should not be empty" });
      return;
    }
    if ((currentlyStudying === true && endDate === undefined) || (currentlyStudying !== true && endDate !== undefined)) {
      this.setState({ isEmpty: false });
    } else {
      this.setState({ isEmpty: true });
      return;
    }
    if (this.state.endDate === "" || this.state.endDate === null) {
      this.setState({ endDate: undefined, isEmpty: true, inValidMessage: "Please fill any one field" });
      return;
    }
    let EducationalDetails = {
      instituteName: this.state.instituteName,
      location: this.state.location,
      degree: this.state.degree,
      stream: this.state.stream,
      percentage: this.state.percentage,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentlyStudying: this.state.currentlyStudying
    };
    if (this.state.edit !== true) {
      let route = "/educational-details";

      let res = await addComponent(route, EducationalDetails);
      if (res.success) {
        this.props.onSave(res);
        toast.success("✔️ added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🛇details cant be added.${res.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let route = "/educational-details/";
      let id = this.state.educationDetailsId;
      let editRes = await editComponent(route, id, EducationalDetails);
      console.log(editRes);
      if (editRes.success) {
        this.props.onSave(editRes);
        toast.success("✔️ edited successfully", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🛇details cant be edited.${editRes.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };

  render() {
    return (
      <div>
        <Form className="container">
          <Row>
            <Col>
              <FormGroup>
                <Label for="Institute name">Institute name*</Label>
                <InputGroup>
                  <Input
                    invalid={this.state.instituteName && this.state.instituteName.length > 0 ? this.state.isInstInvalid : this.state.isEmpty}
                    valid={this.state.instituteNameValid}
                    type="text"
                    name="instituteName"
                    value={this.state.instituteName}
                    onChange={this.handleChange}
                    placeholder="Name of Institute"
                    required
                  />
                  <FormFeedback>{this.state.instValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <FormGroup>
                <Label for="degree">Degree*</Label>
                <InputGroup>
                  <Input
                    invalid={this.state.degree && this.state.degree.length > 0 ? this.state.isDegInvalid : this.state.isEmpty}
                    valid={this.state.degreeValid}
                    type="text"
                    name="degree"
                    value={this.state.degree}
                    onChange={this.handleChange}
                    placeholder="Degree"
                    required
                  />
                  <FormFeedback>{this.state.degValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="stream">Stream(if any)</Label>
                <Input type="text" name="stream" value={this.state.stream} onChange={this.handleChange} required />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label for="percentage">Percentage</Label>
                <InputGroup>
                  <Input
                    invalid={this.state.percentage && this.state.percentage.length > 0 ? this.state.isPercentageInvalid : this.state.isEmpty}
                    valid={this.state.percentageValid}
                    type="number"
                    value={this.state.percentage}
                    onChange={this.handleChange}
                    name="percentage"
                    min="0"
                    max="100"
                  />
                  <FormFeedback>{this.state.percentageValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="location">Address*</Label>
                <InputGroup>
                  <Input
                    invalid={this.state.location && this.state.location.length > 0 ? this.state.isLocInvalid : this.state.isEmpty}
                    valid={this.state.locationNameValid}
                    type="textarea"
                    name="location"
                    value={this.state.location}
                    onChange={this.handleChange}
                    placeholder="Address of the Institute"
                    required
                  />
                  <FormFeedback>{this.state.locValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="start_date">Start date*</Label>
                <InputGroup>
                  <Input
                    invalid={this.state.startDate && this.state.startDate.length > 0 ? this.state.isStartDateInvalid : this.state.isEmpty}
                    valid={this.state.startDateValid}
                    type="date"
                    value={this.state.startDate}
                    onChange={this.handleChange}
                    name="startDate"
                    required
                  />
                  <FormFeedback>{this.state.startDateValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="end_date">End date</Label>
                <InputGroup>
                  <Input
                    type="date"
                    invalid={
                      this.state.currentlyStudying
                        ? undefined
                        : this.state.endDate && this.state.endDate.length > 0
                        ? this.state.isEndDateInvalid
                        : this.state.isEmpty
                    }
                    valid={this.state.currentlyStudying ? undefined : this.state.endDateValid}
                    value={this.state.endDate}
                    disabled={this.state.currentlyStudying}
                    onChange={this.handleChange}
                    id="end"
                    name="endDate"
                    required
                  />
                  <FormFeedback>{this.state.endDateValidMessage || this.state.message}</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" />
            <Col md="6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  className="btn-lg"
                  id="currentlyStudying"
                  name="currentlyStudying"
                  checked={this.state.currentlyStudying}
                  onChange={this.handleChange}
                  invalid={this.state.currentlyStudying && this.state.currentlyStudying.length > 0 ? this.state.iscurrentlyStudyingInValid : this.state.isEmpty}
                  valid={this.state.currentlyStudyingValid}
                />
                <Label for="currentlyStudying">Currently Studying</Label>
                <FormFeedback>{this.state.inValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
