import React, { Component } from "react";
import { Button, Form, FormGroup, Input, FormFeedback } from "reactstrap";
import { addComponent, editComponent } from "../../services/APICall";
import { toast } from "react-toastify";
import { priBtnBgColor } from "../../constants/Colors";

const urlRegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

class ManageWebsite extends Component {
  constructor(props) {
    super(props);
    this.state = { isEdit: false, validInput: undefined, messageForInput: undefined, website: "" };
  }

  componentDidMount = () => {
    if (this.props.site !== undefined) {
      this.setState({
        website: this.props.site,
        isEdit: true
      });
    } else {
      this.setState({ isEdit: false });
    }
    this.props.disableBackDrop(false);
  };

  render() {
    return (
      <div className="container mt-2">
        <Form onSubmit={this.handleButtonSubmit}>
          <FormGroup>
            <Input
              valid={this.state.validInput}
              invalid={this.state.validInput !== undefined && !this.state.validInput}
              type="text"
              id="website"
              name="website"
              value={this.state.website}
              onChange={this.handleInputChange}
              placeholder="e.g: https://github.com"
              autoFocus
            />
            <FormFeedback valid={this.state.validInput}>{this.state.messageForInput}</FormFeedback>
          </FormGroup>
          <Button type="submit" style={{ backgroundColor: priBtnBgColor, border: "none" }} className="btn btn-block px-4 mt-4">
            {this.props.children}
          </Button>
        </Form>
      </div>
    );
  }

  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });

    // to disable backDrop if user start type or updating ..
    this.props.disableBackDrop(true);

    // check whether input is a valid url or nor ..
    urlRegExp.test(value)
      ? this.setState({ validInput: true, messageForInput: "Awesome!🙂 You are good to go." })
      : this.setState({ validInput: false, messageForInput: "Aww!😑 that url is not valid." });
  };

  getValidURL = url => {
    if (/http:\/\//.test(url) || /https:\/\//.test(url)) return url;
    return "http://" + url;
  };

  handleButtonSubmit = async event => {
    //checks whether input is empty or not ..
    event.preventDefault();
    if (this.state.website === "" || this.state.website === undefined) {
      this.setState({ validInput: false, messageForInput: "😑 should not be empty." });
      return;
    }
    if (this.state.validInput === false) {
      this.setState({ validInput: false, messageForInput: "🙃 Type valid url." });
      return;
    }

    // check whether it is edit or add ..
    if (this.state.isEdit === false) {
      const website = {
        websites: this.getValidURL(this.state.website.trim())
      };
      const websiteRoute = "/websites";
      let response = await addComponent(websiteRoute, website);
      if (response !== null && response.success === true) {
        this.props.onSave(response.data.websites);
        toast.success("✔️ Added successfully", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        //TODO:
      }
    } else if (this.state.isEdit === true) {
      const website = {
        oldWebsite: this.props.site,
        newWebsite: this.getValidURL(this.state.website.trim())
      };
      let response = await editComponent("/websites", "", website);
      if (response !== null && response.success === true) {
        toast.success("✔️ Updated successfully", { position: toast.POSITION.BOTTOM_RIGHT });
        this.props.onSave(response.data.websites);
      } else {
        //TODO:
      }
    }
  };
}

export default ManageWebsite;
