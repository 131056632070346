import React, { Component } from "react";
import ManageProfessionalSummary from "../components/manage/ManageProfessionalSummary";
import { viewComponent, deleteComponent } from "../services/APICall";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import BtnSpan from "../components/BtnSpan";
import { Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Loader } from "../constants/Loader";
const $ = window.$;
class ProfessionalSummary extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = { showModal: false, technicalSummaryDetails: [], isHovering: false };
  }

  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }
    let response = await viewComponent("/prof-summary");
    if (response !== null && response.success) {
      this.setState({ technicalSummaryDetails: response.data, success: response.success });
    } else {
      this.setState({ technicalSummaryDetails: [], success: false });
    }
  };
  handleAdd = async () => {
    this.setState({ selectedTechnicalSummaryDetail: undefined, showModal: true });
  };
  handleEdit = async object => {
    this.setState({ selectedTechnicalSummaryDetail: object, showModal: true });
  };
  handleDelete = async techSummaryDelete => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/prof-summary/", techSummaryDelete._id);
            if (response.success) {
              let techSummaryObj = this.state.technicalSummaryDetails.filter(data => data !== techSummaryDelete);
              this.setState({ technicalSummaryDetails: techSummaryObj });
              toast.success(`️️️✔️ Removed successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newTechSummary, isAdd, oldTechSummary) => {
    let { technicalSummaryDetails } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ technicalSummaryDetails: [...technicalSummaryDetails, newTechSummary] });
    } else {
      let temp = technicalSummaryDetails;
      let index = temp.findIndex(obj => obj._id === oldTechSummary._id);
      temp.splice(index, 1, newTechSummary);
      this.setState({ technicalSummaryDetails: temp });
    }
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };

  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div className={!this.state.mobile ? "mt-5" : ""}>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-summary">
                  Professional Summary
                </a>
              </h4>
            </div>
            <div id="collapse-summary" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <Loader width={540} />
                      </div>
                    ) : this.state.technicalSummaryDetails.length > 0 ? (
                      <div>
                        {this.state.technicalSummaryDetails.map((currentDetail, key) => {
                          return (
                            <div
                              key={key}
                              className={key === 0 ? "p-2 py-4" : "p-2 py-4 border-top"}
                              onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                              onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                            >
                              <span className="float-right">
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.handleEdit(currentDetail)}
                                  BtnSpanClass={"fas fa-pen mr-3"}
                                />
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.handleDelete(currentDetail)}
                                  BtnSpanClass={"fas fa-trash"}
                                />
                              </span>
                              <div className="h5">{currentDetail.title}</div>
                              <p dangerouslySetInnerHTML={{ __html: currentDetail.description && currentDetail.description }} />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add your project details...!</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static" size="lg">
                  <ModalHeader close={closeBtn} className="modalStyle">
                    Professional Summary
                  </ModalHeader>
                  <ModalBody className="modalScroll">
                    <ManageProfessionalSummary
                      ref={this.child}
                      onSubmit={true}
                      onSave={this.save}
                      onClose={this.toggle}
                      selectedTechSummaryDetail={this.state.selectedTechnicalSummaryDetail}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <div className="float-right">
                      <Button type="button" id="cnclBtn" className="btn mr-2 px-4" onClick={this.toggle}>
                        Cancel
                      </Button>
                      <Button className="btn  px-4" id="saveBtn" onClick={() => this.child.current.handleSubmit()}>
                        Save
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfessionalSummary;
