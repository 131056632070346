import React, { Component } from "react";
import { priBtnBgColor, priBtnHoverBgColor } from "../constants/Colors";

class BtnSpan extends Component {
  constructor(props) {
    super(props);
    this.state = { isHovering: false, styles: { color: priBtnBgColor } };
  }
  render() {
    return (
      <>
        {this.props.isHovering && (
          <span
            onMouseEnter={() => this.setState({ styles: { color: priBtnHoverBgColor, transform: "scale(1.3)" } })}
            onMouseLeave={() => this.setState({ styles: { color: priBtnBgColor } })}
            onClick={this.props.onClickFunction}
            style={{ cursor: "pointer", ...this.state.styles }}
            className={this.props.BtnSpanClass}
          />
        )}
      </>
    );
  }
}

export default BtnSpan;

//"#D6D6E7"
