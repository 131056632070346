import React, { Component } from "react";
import { Form, FormGroup, Label, Input, FormFeedback, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { addComponent, editComponent } from "../../services/APICall";
import RTETextEditor from "../RTETextEditor";
class ManageProject extends Component {
  constructor(props) {
    super(props);
    this.state = { projectTitle: "" };
  }
  onEditorChange = value => {
    this.setState({ description: value }, () => {});
  };
  handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
    switch (name) {
      case "projectTitle": {
        const regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({
              projectTitleValid: true,
              isProjectTitleInValid: false,
              isEmpty: false,
              projectTitleValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              projectTitleValid: false,
              isProjectTitleInValid: true,
              isEmpty: false,
              projectTitleValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({
              projectTitleValid: false,
              isProjectTitleInValid: true,
              isEmpty: true,
              projectTitleValidMessage: "Should not be empty"
            });

        break;
      }
      default: {
      }
    }
  };
  componentDidMount = async => {
    if (this.props.selectedProjectDetail !== undefined) {
      this.setState({
        projectTitle: this.props.selectedProjectDetail.projectTitle,
        description: this.props.selectedProjectDetail.description,
        _id: this.props.selectedProjectDetail._id,
        isEdit: true
      });
    } else {
      this.setState({ isEdit: false });
    }
  };
  handleSubmit = async () => {
    if (this.state.projectTitle === undefined || this.state.projectTitle === "" || this.state.description === undefined || this.state.description === "") {
      this.setState({ isEmpty: true, message: "cannot be empty" });
      return;
    }
    let projectDetails = {
      projectTitle: this.state.projectTitle,
      description: this.state.description && this.state.description.toString("html")
    };
    if (this.state.isEdit === false) {
      let response = await addComponent("/projects", projectDetails);
      if (response && response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success("✔️ Project details Added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to add.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await editComponent("/projects/", this.state._id, projectDetails);
      if (response && response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success("✔️ Project details Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };
  render() {
    return (
      <>
        <Form className="container">
          <Row>
            <Col>
              <FormGroup>
                <Label for="projectTitle">Project-Title*</Label>
                <Input
                  type="text"
                  name="projectTitle"
                  value={this.state.projectTitle}
                  onChange={this.handleChange}
                  placeholder="What's your Project Title ?"
                  invalid={this.state.projectTitle && this.state.projectTitle.length > 0 ? this.state.isProjectTitleInValid : this.state.isEmpty}
                  valid={this.state.projectTitleValid}
                />
                <FormFeedback>{this.state.projectTitleValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="description">Description</Label>
                <RTETextEditor
                  onChange={this.onEditorChange}
                  isAdd={this.state.isEdit}
                  existingSummary={this.props.selectedProjectDetail && this.props.selectedProjectDetail.description}
                />
                <span className="descVal">{this.state.isEmpty ? "this field can't be empty" : ""}</span>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default ManageProject;
