import React, { Component } from "react";
import { parseJwt, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, LINKEDIN_ID, DEFAULT_IMAGE_URL, LINKEDIN_REDIRECT_URI, LINKEDIN_SECRET_KEY } from "../constants/Constant";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { registeredSocialUser, userAuthentication, checkRegistration, getLinkedinDetails } from "../services/APICall";
import { priBtnBgColor } from "../constants/Colors";
import { withRouter } from "react-router-dom";
class SocialLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  handleLinkedinSuccess = async data => {
    let authCode = data.code;
    let linkedinData = {
      grant_type: "authorization_code",
      code: authCode,
      redirect_uri: LINKEDIN_REDIRECT_URI,
      client_id: LINKEDIN_ID,
      client_secret: LINKEDIN_SECRET_KEY
    };
    let tokenFromResponse = await getLinkedinDetails("/auth-linkedin-user", linkedinData);
    if (tokenFromResponse && tokenFromResponse.success) {
      this.setState({ linkedinUserProfile: tokenFromResponse.data });
      let dataToSend = {
        emailId: this.state.linkedinUserProfile.email,
        linkedinId: this.state.linkedinUserProfile.id
      };
      let result = await checkRegistration("/check-registration", dataToSend);
      if (result && result.success === true) {
        let loginData = {
          FirstName: this.state.linkedinUserProfile.firstName,
          LastName: this.state.linkedinUserProfile.lastName,
          Email: this.state.linkedinUserProfile.email,
          LinkedinId: this.state.linkedinUserProfile.id,
          socialSugar: "mounamGaaneEdagamaniMokkaNeekuCheputhundiEdhiginaKoddhiiOdhagamaniArdhamanduloUndi"
        };
        let response = await registeredSocialUser("/register-social", loginData);
        if (response && response.success) {
          localStorage.setItem("stuworx-token", response.token);
          let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
          let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
          if (apiResponse.success) {
            localStorage.setItem("token", apiResponse.data.token);
            this.props.history.push("/");
          } else {
            toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
            return;
          }
        }
      }
      if (result && result.success === false) {
        let registerData = {
          FirstName: this.state.linkedinUserProfile.firstName,
          LastName: this.state.linkedinUserProfile.lastName,
          Email: this.state.linkedinUserProfile.email,
          LinkedinId: this.state.linkedinUserProfile.id,
          ImageUrl: this.state.linkedinUserProfile.profileUrl !== undefined ? this.state.linkedinUserProfile.profileUrl : DEFAULT_IMAGE_URL,
          RoleID: 2,
          FieldOfStudy: "CSE"
        };
        let response = await registeredSocialUser("/register-social", registerData);
        if (response && response.success) {
          localStorage.setItem("stuworx-token", response.token);
          let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
          let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
          if (apiResponse.success) {
            localStorage.setItem("token", apiResponse.data.token);
            this.props.history.push("/");
          } else {
            toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
            return;
          }
        }
      }
    } else {
      toast.info(`🚫 Oops! Failed to Login`, { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };

  handleLinkedinFailure = error => {
    toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
  };

  sendUserDetails = async () => {
    let registerData = {
      FirstName: this.state.givenName,
      LastName: this.state.familyName,
      Email: this.state.fbDetails && this.state.fbDetails.email,
      FBId: this.state.fbDetails && this.state.fbDetails.id,
      ImageUrl: this.state.fbDetails && this.state.fbDetails.picture.data.url ? this.state.fbDetails.picture.data.url : DEFAULT_IMAGE_URL,
      RoleID: 2,
      FieldOfStudy: "CSE"
    };
    let response = await registeredSocialUser("/register-social", registerData);
    if (response && response.success) {
      localStorage.setItem("stuworx-token", response.token);
      let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
      let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
      if (apiResponse.success) {
        localStorage.setItem("token", apiResponse.data.token);
        this.props.history.push("/");
      } else {
        toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
        return;
      }
    }
  };

  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    const responseFacebook = async response => {
      let data;
      let fbResponse = response;
      this.setState({ fbDetails: response });
      if (fbResponse.email) {
        data = {
          emailId: fbResponse.email,
          fbId: fbResponse.id
        };
      } else {
        data = {
          fbId: fbResponse.id
        };
      }
      let result = await checkRegistration("/check-registration", data);
      if (result && result.success === true) {
        let registerData = {
          Email: fbResponse.email,
          FBId: fbResponse.id,
          socialSugar: "mounamGaaneEdagamaniMokkaNeekuCheputhundiEdhiginaKoddhiiOdhagamaniArdhamanduloUndi"
        };
        let response = await registeredSocialUser("/register-social", registerData);
        if (response && response.success) {
          localStorage.setItem("stuworx-token", response.token);
          let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
          let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
          if (apiResponse.success) {
            localStorage.setItem("token", apiResponse.data.token);
            this.props.history.push("/");
          } else {
            toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
            return;
          }
        } else {
          toast.info(`${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
        }
      }
      if (result && result.success === false) {
        this.setState({ showModal: true });
      }
    };
    const responseGoogle = async response => {
      let data;
      let googleResponse = response;
      if (googleResponse.profileObj.email) {
        data = {
          emailId: googleResponse.profileObj.email,
          googleId: googleResponse.googleId
        };
      } else {
        data = {
          googleId: googleResponse.googleId
        };
      }
      let result = await checkRegistration("/check-registration", data);
      if (result && result.success === true) {
        let loginData = {
          FirstName: googleResponse.profileObj.givenName,
          LastName: googleResponse.profileObj.familyName,
          Email: googleResponse.profileObj.email,
          GoogleId: googleResponse.googleId,
          socialSugar: "mounamGaaneEdagamaniMokkaNeekuCheputhundiEdhiginaKoddhiiOdhagamaniArdhamanduloUndi"
        };
        let response = await registeredSocialUser("/register-social", loginData);
        if (response && response.success) {
          localStorage.setItem("stuworx-token", response.token);
          let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
          let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
          if (apiResponse.success) {
            localStorage.setItem("token", apiResponse.data.token);
            this.props.history.push("/");
          } else {
            toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
            return;
          }
        }
      }
      if (result && result.success === false) {
        let registerData = {
          FirstName: googleResponse.profileObj.givenName,
          LastName: googleResponse.profileObj.familyName,
          Email: googleResponse.profileObj.email,
          GoogleId: googleResponse.googleId,
          ImageUrl: googleResponse.profileObj.imageUrl ? googleResponse.profileObj.imageUrl : DEFAULT_IMAGE_URL,
          RoleID: 2,
          FieldOfStudy: "CSE"
        };
        let response = await registeredSocialUser("/register-social", registerData);
        if (response && response.success) {
          localStorage.setItem("stuworx-token", response.token);
          let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));
          let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);
          if (apiResponse.success) {
            localStorage.setItem("token", apiResponse.data.token);
            this.props.history.push("/");
          } else {
            toast.info(`🚫 Oops! Login Failed`, { position: toast.POSITION.BOTTOM_RIGHT });
            return;
          }
        }
      }
    };
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              buttonText="GOOGLE"
              className="google"
              icon={false}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              disabledStyle
            />
          </div>
          <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              className="facebook"
              autoLoad={false}
              size={"small"}
              textButton="FACEBOOK"
              fields="name,email,picture"
              callback={responseFacebook}
            />
          </div>
          <div className=" col-xs-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-0">
            <LinkedIn
              clientId={LINKEDIN_ID}
              onFailure={this.handleLinkedinFailure}
              onSuccess={this.handleLinkedinSuccess}
              redirectUri={LINKEDIN_REDIRECT_URI}
              className="linkedin"
              scope="r_liteprofile r_emailaddress"
            >
              LINKEDIN
            </LinkedIn>
          </div>
        </div>
        <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static">
          <ModalHeader close={closeBtn} className="modalStyle">
            Please fill the details below
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="givenName">First-Name</Label>
                <Input type="text" name="givenName" id="givenName" value={this.state.givenName} onChange={this.handleInputChange} />
              </FormGroup>
              <FormGroup>
                <Label for="familyName">Last-Name</Label>
                <Input type="text" name="familyName" id="familyName" value={this.state.familyName} onChange={this.handleInputChange} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.sendUserDetails} style={{ backgroundColor: priBtnBgColor, border: "none" }}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SocialLogin);
