import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { viewComponent } from "../../services/APICall";

class SelectProject extends Component {
  constructor(props) {
    super(props);
    this.state = { SelectedProjects: {} };
  }
  Projects = [];
  componentDidMount = async () => {
    let response = await viewComponent("/projects");
    if (response !== null && response.success) {
      let SelectedProjects = {};
      this.setState({ projectInfo: response.data, SelectedProjects: SelectedProjects, success: true });
      if (this.props.edited === undefined) {
        response.data.forEach(element => {
          SelectedProjects[element._id] = true;
        });
        this.state.projectInfo.map(current => this.Projects.push(current._id));
        this.props.onGetProject(this.Projects);
      } else {
        response.data.forEach(element => {
          SelectedProjects[element._id] = false;
        });
        this.props.edited.forEach(element => {
          SelectedProjects[element] = true;
        });
        this.props.edited.map(current => this.Projects.push(current));
        this.props.onGetProject(this.Projects);
      }
    } else {
      this.setState({ projectInfo: [], success: false });
    }
  };
  handleChange = async proId => {
    let projectInfo = { ...this.state.SelectedProjects };
    projectInfo[proId] = !projectInfo[proId];

    this.setState({
      SelectedProjects: projectInfo
    });
    if (this.state.SelectedProjects[proId] === true) {
      this.Projects = this.Projects.filter(e => e !== proId);
    }
    if (this.state.SelectedProjects[proId] === false) {
      this.Projects.push(proId);
    }
    this.props.onGetProject(this.Projects);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5">Projects</div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.projectInfo.length > 0 ? (
                this.state.projectInfo.map((currentProject, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentProject._id}
                          name={currentProject._id}
                          checked={this.state.SelectedProjects[currentProject._id] && this.state.SelectedProjects[currentProject._id]}
                          onChange={() => this.handleChange(currentProject._id)}
                        />
                        <Label for={currentProject._id}>{currentProject.projectTitle}</Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>You have not added any projects</h6>
                  <div className="disabled">Please add it in your Profile to choose</div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SelectProject;
