import React, { Component } from "react";
import { Badge } from "reactstrap";
import { priBtnBgColor } from "../constants/Colors";

class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // color: "secondary"
      styles: { cursor: "pointer", backgroundColor: priBtnBgColor }
    };
  }

  render() {
    const { tag } = this.props;
    return (
      <>
        <Badge
          onMouseEnter={() => this.setState({ styles: { cursor: "pointer", backgroundColor: "#464484" } })}
          onMouseLeave={() => this.setState({ styles: { cursor: "pointer", backgroundColor: priBtnBgColor } })}
          style={this.state.styles}
          // color={this.state.color}
          className="m-1 p-2"
          pill
        >
          {tag}
          <span onClick={() => this.props.remove(tag)}>&nbsp;&#x2716;</span>
        </Badge>
      </>
    );
  }
}

export default Tag;
