// localhost
// export const API_URL = "http://localhost:3005";
// export const WEB_URL = "http://localhost:3000/";
// export const RAZOR_PAY_KEY = "rzp_test_mSyHpcZPGYN8yK";
// export const Cash_Free = {
//     App_Id: "600463ca2e92ab0dc195a66a4006",
//     Mode: "TEST"
// }
// export const PayU = {
//     key: "kYaE7aMb"
// };
// export const InstamojoPaymentLink = "https://test.instamojo.com/@developer_0b32c/l5207aaca046c4a57be6ce63653f2f1a4/"


// Prod
export const API_URL = "https://resumeapi.stuworx.com/";
export const WEB_URL = "https://resume.stuworx.com/";
export const RAZOR_PAY_KEY = "rzp_live_9yNKh8JSObaPTK";
export const Cash_Free = {
    App_Id: "1514693560b410ebf8e94a93264151",
    Mode: "PROD"
}
export const PayU = {
    key: "kYaE7aMb"
};
export const InstamojoPaymentLink = "https://www.instamojo.com/@contenterra/l9c664e7a949140c9808793545c423309/";

//dev
// export const API_URL = "http://13.126.119.s187:3005";
// export const WEB_URL="localhost:3000/";
// export const Cash_Free={
//     App_Id:"600463ca2e92ab0dc195a66a4006",
//     Mode:"PROD"
// }
// export const PayU = {
//     key: "kYaE7aMb"
// };

//common
export const IMG_URL = "https://s3-ap-southeast-1.amazonaws.com/stuworx-profile-images/";
export const AWS_URL = "https://stuworx-resume-docs.s3.amazonaws.com/";
export const STUWORX_URL = "https://api.stuworx.com";
export const LINKEDIN_URL = "https://www.linkedin.com";
