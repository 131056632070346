export async function httpClient(url, type, data) {
  let response = await fetch(url, {
    method: type,
    body: JSON.stringify(data),
    headers: { "Content-type": "application/json", at: localStorage.getItem("token") }
  });
  response = await response.json();
  if (response.success) {
    return response;
  } else {
    return response;
  }
}
export async function httpLogin(url, type, data) {
  let response = await fetch(url, {
    method: type,
    body: JSON.stringify(data),
    headers: { "Content-type": "application/json" }
  });
  response = await response.json();
  if (response.success) {
    return response;
  } else {
    return response;
  }
}
