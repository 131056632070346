import React, { Component } from "react";
import { Form, FormGroup, Label, Input, FormFeedback, Row, Col } from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { addComponent, editComponent } from "../../services/APICall";
import RTETextEditor from "../RTETextEditor";
class ManageWorkExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlyWorking: false,
      jobType: "Full Time",
      jobTitle: "",
      companyName: "",
      startDate: "",
      endDate: ""
    };
  }

  onEditorChange = value => {
    this.setState({ summary: value }, () => {});
  };

  //TODO
  componentDidMount = async => {
    if (this.props.selectedExperienceDetail !== undefined) {
      this.setState({
        jobTitle: this.props.selectedExperienceDetail.jobTitle,
        companyName: this.props.selectedExperienceDetail.companyName,
        location: this.props.selectedExperienceDetail.location,
        jobType: this.props.selectedExperienceDetail.jobType,
        startDate: moment(this.props.selectedExperienceDetail.startDate).format("YYYY-MM-DD"),
        endDate: this.props.selectedExperienceDetail.endDate && moment(this.props.selectedExperienceDetail.endDate).format("YYYY-MM-DD"),
        currentlyWorking: this.props.selectedExperienceDetail.currentlyWorking,
        summary: this.props.selectedExperienceDetail.summary,
        _id: this.props.selectedExperienceDetail._id,
        isEdit: true
      });
    } else {
      this.setState({ isEdit: false });
    }
  };

  handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
    if (name === "currentlyWorking" && this.state.currentlyWorking === false) {
      this.setState({ currentlyWorking: true, endDate: undefined });
    } else {
      this.setState({ currentlyWorking: false });
    }
    switch (name) {
      case "jobTitle": {
        const regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({
              jobTitleValid: true,
              isJobTitleInValid: false,
              isEmpty: false,
              jobTitleValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              jobTitleValid: false,
              isJobTitleInValid: true,
              isEmpty: false,
              jobTitleValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({
              jobTitleValid: false,
              isJobTitleInValid: true,
              isEmpty: true,
              jobTitleValidMessage: "Should not be empty"
            });

        break;
      }

      case "companyName": {
        const regExp = /.*\S.*/;
        regExp.test(value.trim())
          ? this.setState({
              companyNameValid: true,
              isCompanyNameInValid: false,
              isEmpty: false,
              companyNameValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              companyNameValid: false,
              isCompanyNameInValid: true,
              isEmpty: false,
              companyNameValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({
              companyNameValid: false,
              isCompanyNameInValid: true,
              isEmpty: true,
              companyNameValidMessage: "Should not be empty"
            });
        break;
      }
      case "location": {
        const regExp = /^[ A-Za-z0-9_,@./#&+-]*$/;
        regExp.test(value)
          ? this.setState({
              locationValid: true,
              isLocationInValid: false,
              isEmpty: false,
              locationValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              locationValid: false,
              isLocationInValid: true,
              isEmpty: false,
              locationValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({
              locationValid: false,
              isLocationInValid: true,
              isEmpty: true,
              locationValidMessage: "Should not be empty"
            });
        break;
      }
      case "startDate": {
        const date = new Date().getTime();
        const currentDate = new Date(value).getTime();
        const currentVal = currentDate;
        if (currentVal < date) {
          this.setState({ startDateValid: true, isStartDateInvalid: false, isEmpty: false, startDateValidMessage: "" });
        } else {
          value.length > 0
            ? this.setState({
                startDateValid: false,
                isStartDateInvalid: true,
                isEmpty: false,
                startDateValidMessage: "Select date correctly"
              })
            : this.setState({ startDateValid: false, isStartDateInvalid: true, isEmpty: true, startDateValidMessage: "should not be empty" });
        }
        break;
      }

      case "endDate": {
        const startDate = new Date(this.state.startDate).getTime();
        const currentDate = new Date(value).getTime();

        if (currentDate > startDate) {
          this.setState({ endDateValid: true, isEndDateInvalid: false, isEmpty: false, endDateValidMessage: "end date cannot be before starting date" });
        } else {
          value.length > 0
            ? this.setState({
                endDateValid: false,
                isEndDateInvalid: true,
                isEmpty: false,
                endDateValidMessage: "select date correctly"
              })
            : this.setState({ endDateValid: false, isEndDateInvalid: true, isEmpty: true, endDateValidMessage: "Should not be empty" });
        }
        break;
      }
      case "currentlyWorking": {
        this.setState({ endDateValid: true, isEndDateInvalid: false, isEmpty: false, endDateValidMessage: "" });
        break;
      }
      default: {
      }
    }
  };

  handleSubmit = async () => {
    const { jobTitle, companyName, startDate, endDate, currentlyWorking } = this.state;
    if (jobTitle === undefined || companyName === undefined || startDate === undefined || jobTitle === "" || companyName === "" || startDate === "") {
      this.setState({ isEmpty: true, message: "cannot be empty" });
      return;
    }
    if ((currentlyWorking === true && endDate === undefined) || (currentlyWorking === false && endDate !== undefined)) {
      this.setState({ isEmpty: false });
    } else {
      this.setState({ isEmpty: true, inValidMessage: "Please fill any one field" });
      return;
    }
    if (this.state.endDate === "" || this.state.endDate === null) {
      this.setState({ endDate: undefined, isEmpty: true, inValidMessage: "Please fill any one field" });
      return;
    }
    const job = {
      jobTitle: this.state.jobTitle.trim(),
      companyName: this.state.companyName.trim(),
      location: this.state.location,
      jobType: this.state.jobType,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      currentlyWorking: this.state.currentlyWorking,
      summary: this.state.summary && this.state.summary.toString("html")
    };
    if (this.state.isEdit === false) {
      let response = await addComponent("/experience-details", job);
      if (response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success("✔️ Work details Added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to add.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await editComponent("/experience-details/", this.state._id, job);
      if (response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success("✔️ Work details Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };

  render() {
    return (
      <div>
        <Form className="container">
          <Row>
            <Col>
              <FormGroup>
                <Label for="inputJob">Job Title</Label>
                <Input
                  type="text"
                  id="inputJob"
                  name="jobTitle"
                  value={this.state.jobTitle}
                  onChange={this.handleChange}
                  placeholder="What's your designation ?"
                  invalid={this.state.jobTitle && this.state.jobTitle.length > 0 ? this.state.isJobTitleInValid : this.state.isEmpty}
                  valid={this.state.jobTitleValid}
                />
                <FormFeedback>{this.state.jobTitleValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={this.state.companyName}
                  onChange={this.handleChange}
                  placeholder="Where do you work ?"
                  invalid={this.state.companyName && this.state.companyName.length > 0 ? this.state.isCompanyNameInValid : this.state.isEmpty}
                  valid={this.state.companyNameValid}
                />
                <FormFeedback>{this.state.companyNameValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="inputJobType">Job Type</Label>
                <Input type="select" id="inputJobType" name="jobType" value={this.state.jobType} onChange={this.handleChange}>
                  <option>Full Time</option>
                  <option>Intern</option>
                  <option>Part Time</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  type="textarea"
                  id="address"
                  name="location"
                  value={this.state.location}
                  onChange={this.handleChange}
                  placeholder="Where is the company located ?"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="comment">Summary</Label>
                <RTETextEditor
                  onChange={this.onEditorChange}
                  isAdd={this.state.isEdit}
                  existingSummary={this.props.selectedExperienceDetail && this.props.selectedExperienceDetail.summary}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="startDate">From</Label>
                <Input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  invalid={this.state.startDate && this.state.startDate.length > 0 ? this.state.isStartDateInvalid : this.state.isEmpty}
                  valid={this.state.startDateValid}
                />
                <FormFeedback>{this.state.startDateValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="endDate">To</Label>
                <Input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={this.state.endDate}
                  disabled={this.state.currentlyWorking}
                  onChange={this.handleChange}
                  invalid={
                    this.state.currentlyWorking
                      ? undefined
                      : this.state.endDate && this.state.endDate.length > 0
                      ? this.state.isEndDateInvalid
                      : this.state.isEmpty
                  }
                  valid={this.state.currentlyWorking ? undefined : this.state.endDateValid}
                />
                <FormFeedback>{this.state.endDateValidMessage || this.state.message || this.state.inValidMessage}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6" />
            <Col>
              <FormGroup check>
                <Input
                  type="checkbox"
                  className="btn-lg"
                  id="currentlyWorking"
                  name="currentlyWorking"
                  checked={this.state.currentlyWorking}
                  onChange={this.handleChange}
                  invalid={this.state.currentlyWorking && this.state.currentlyWorking.length > 0 ? this.state.isCurrentlyWorkingInValid : this.state.isEmpty}
                  valid={this.state.currentlyWorkingValid}
                />
                <Label for="currentlyWorking">Currently Working</Label>
                <FormFeedback>{this.state.inValidMessage || this.state.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
export default ManageWorkExperience;
