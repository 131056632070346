import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { viewComponent } from "../../services/APICall";

class SelectEducationalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedEducationInfo: {} };
  }
  education = [];
  componentDidMount = async () => {
    let response = await viewComponent("/educational-details");
    if (response !== null && response.success) {
      let selectedEducationInfo = {};
      this.setState({ educationalInfo: response.data, selectedEducationInfo: selectedEducationInfo, success: true });
      if (this.props.edited === undefined) {
        response.data.forEach(element => {
          selectedEducationInfo[element._id] = true;
        });
        this.state.educationalInfo.map(current => this.education.push(current._id));
        this.props.onGetEducation(this.education);
      } else {
        response.data.forEach(element => {
          selectedEducationInfo[element._id] = false;
        });
        this.props.edited.forEach(element => {
          selectedEducationInfo[element] = true;
        });
        this.props.edited.map(current => this.education.push(current));
        this.props.onGetEducation(this.education);
      }
      // response.data.forEach(element => {
      //   selectedEducationInfo[element._id] = true;
      // });
    } else {
      this.setState({ educationalInfo: [], success: false });
    }
  };
  handleChange = async eduId => {
    let educationalInfo = { ...this.state.selectedEducationInfo };
    educationalInfo[eduId] = !educationalInfo[eduId];

    this.setState({
      selectedEducationInfo: educationalInfo
    });
    if (this.state.selectedEducationInfo[eduId] === true) {
      this.education = this.education.filter(e => e !== eduId);
    }
    if (this.state.selectedEducationInfo[eduId] === false) {
      this.education.push(eduId);
    }
    this.props.onGetEducation(this.education);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5">Educational Info</div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.educationalInfo.length > 0 ? (
                this.state.educationalInfo.map((currentEduInfo, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentEduInfo._id}
                          name={currentEduInfo._id}
                          checked={this.state.selectedEducationInfo[currentEduInfo._id] && this.state.selectedEducationInfo[currentEduInfo._id]}
                          onChange={() => this.handleChange(currentEduInfo._id)}
                        />
                        <Label for={currentEduInfo._id}>{currentEduInfo.degree}</Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>You have not added any Educational details</h6>
                  <div className="disabled">Please add it in your Profile to choose</div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SelectEducationalInfo;
