import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import RTETextEditor from "../RTETextEditor";
import { toast } from "react-toastify";
import { addComponent, editComponent } from "../../services/APICall";

class ManageProfessionalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = { title: "" };
  }
  onEditorChange = value => {
    this.setState({ description: value }, () => {});
  };
  handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
    switch (name) {
      case "title": {
        const regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value.trim())
          ? this.setState({
              titleValid: true,
              isTitleInValid: false,
              isEmpty: false,
              titleValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              titleValid: false,
              isTitleInValid: true,
              isEmpty: false,
              titleValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({
              titleValid: false,
              isTitleInValid: true,
              isEmpty: true,
              titleValidMessage: "Should not be empty"
            });

        break;
      }
      default: {
      }
    }
  };
  componentDidMount = async () => {
    if (this.props.selectedTechSummaryDetail !== undefined) {
      this.setState({
        title: this.props.selectedTechSummaryDetail.title,
        description: this.props.selectedTechSummaryDetail.description,
        _id: this.props.selectedTechSummaryDetail._id,
        isEdit: true
      });
    } else {
      this.setState({ isEdit: false });
    }
  };
  handleSubmit = async () => {
    if (this.state.title === undefined || this.state.title === "") {
      this.setState({ isEmpty: true, message: "cannot be empty" });
      return;
    }
    if (this.state.description === undefined || this.state.description === "") {
      this.setState({ isSummaryEmpty: true });
      return;
    }
    if (this.state.description !== undefined || this.state.description !== "") {
      this.setState({ isSummaryEmpty: false });
    }
    let technicalSummaryDetails = {
      title: this.state.title,
      description: this.state.description && this.state.description.toString("html")
    };
    if (this.state.isEdit === false) {
      let response = await addComponent("/prof-summary", technicalSummaryDetails);
      if (response && response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success("✔️ Professional Summary details Added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to add.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await editComponent("/prof-summary/", this.state._id, technicalSummaryDetails);
      if (response && response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success("✔️ Professional Summary details Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };
  render() {
    return (
      <div>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="summaryTitle">Summary Title*</Label>
                <Input
                  type="text"
                  id="summaryTitle"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                  placeholder="Title of your Technical Summary"
                  invalid={this.state.title && this.state.title.length > 0 ? this.state.isTitleInValid : this.state.isEmpty}
                  valid={this.state.titleValid}
                />
              </FormGroup>
              <FormFeedback>{this.state.titleValidMessage || this.state.message}</FormFeedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="comment">Professional Summary*</Label>
                <RTETextEditor
                  onChange={this.onEditorChange}
                  isAdd={this.state.isEdit}
                  existingSummary={this.props.selectedTechSummaryDetail && this.props.selectedTechSummaryDetail.description}
                />
                <span className="descVal">{this.state.isSummaryEmpty ? "This field can't be empty" : ""}</span>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default ManageProfessionalSummary;
