import React, { Component } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";

class SelectResumeTitle extends Component {
  constructor(props) {
    super(props);
    this.state = { title: "" };
    this._validateOnDemand = true;
  }
  componentDidMount = async () => {
    if (this.props.edited !== undefined) {
      this.setState({
        title: this.props.edited
      });
      this.props.onGetTitle(this.props.edited);
    }
  };
  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  isValidated = async () => {
    const userInput = this.state.title.trim();
    let isDataValid = false;
    if (userInput.length > 0) {
      this.props.onGetTitle(this.state.title);
      isDataValid = true;
      this.setState({ titleValMsg: "", isEmpty: false });
    } else {
      this.setState({ titleValMsg: "Resume title cannot be empty.", isEmpty: true });
    }
    return isDataValid;
  };

  render() {
    let notValidClasses = {};

    if (typeof this.state.titleVal == "undefined" || this.state.titleVal) {
      notValidClasses.titleCls = "no-error";
    } else {
      notValidClasses.titleCls = "has-error col-md-8";
      notValidClasses.titleValGrpCls = "val-err-tooltip";
    }
    return (
      <div className="py-3">
        <Form>
          <FormGroup className={notValidClasses.titleCls}>
            <Label for="title" className="h5">
              Resume-Title
            </Label>
            <Input
              type="text"
              id="title"
              name="title"
              value={this.state.title}
              onChange={this.handleChange}
              invalid={this.state.isEmpty}
              valid={!this.state.isEmpty}
              placeholder="Enter the name of Your Resume"
              onBlur={this.validationCheck}
              required
            />
            <span className="titleErrorMsg">{this.state.titleValMsg} </span>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default SelectResumeTitle;
