import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Card, CardBody, Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import { deleteComponent, viewComponent } from "../services/APICall";
import BtnSpan from "../components/BtnSpan";
import ManageProject from "../components/manage/ManageProject";
import { Loader } from "../constants/Loader";
const $ = window.$;
class Project extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = { showModal: false, projectDetails: [], isHovering: false };
  }
  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }
    let response = await viewComponent("/projects");
    if (response !== null && response.success) {
      this.setState({ projectDetails: response.data, success: response.success });
    } else {
      this.setState({ projectDetails: [], success: false });
    }
  };
  handleAdd = async () => {
    this.setState({ selectedProjectDetail: undefined, showModal: true });
  };
  handleEdit = async object => {
    this.setState({ selectedProjectDetail: object, showModal: true });
  };
  handleDelete = async projectToDelete => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/projects/", projectToDelete._id);
            if (response.success) {
              let projectObj = this.state.projectDetails.filter(data => data !== projectToDelete);
              this.setState({ projectDetails: projectObj });
              toast.success(`️️️✔️ removed successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newProject, isAdd, oldProject) => {
    let { projectDetails } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ projectDetails: [...projectDetails, newProject] });
    } else {
      let temp = projectDetails;
      let index = temp.findIndex(obj => obj._id === oldProject._id);
      temp.splice(index, 1, newProject);
      this.setState({ projectDetails: temp });
    }
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-project">
                  Projects
                </a>
              </h4>
            </div>
            <div id="collapse-project" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <Loader width={490} />
                      </div>
                    ) : this.state.projectDetails.length > 0 ? (
                      <div>
                        {this.state.projectDetails.map((currentDetail, key) => {
                          return (
                            <div
                              key={key}
                              className={key === 0 ? "p-2 py-4" : "p-2 py-4 border-top"}
                              onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                              onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                            >
                              <span className="float-right">
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.handleEdit(currentDetail)}
                                  BtnSpanClass={"fas fa-pen mr-3"}
                                />
                                <BtnSpan
                                  isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                  onClickFunction={() => this.handleDelete(currentDetail)}
                                  BtnSpanClass={"fas fa-trash"}
                                />
                              </span>
                              <div className="h5">{currentDetail.projectTitle}</div>
                              <p dangerouslySetInnerHTML={{ __html: currentDetail.description && currentDetail.description }} />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add your project details...!</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static" size="lg">
                  <ModalHeader close={closeBtn} className="modalStyle">
                    Project
                  </ModalHeader>
                  <ModalBody className="modalScroll">
                    <ManageProject
                      ref={this.child}
                      onSubmit={true}
                      onSave={this.save}
                      onClose={this.toggle}
                      selectedProjectDetail={this.state.selectedProjectDetail}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <div className="float-right">
                      <Button type="button" id="cnclBtn" className="btn mr-2 px-4" onClick={this.toggle}>
                        Cancel
                      </Button>
                      <Button className="btn  px-4" id="saveBtn" onClick={() => this.child.current.handleSubmit()}>
                        Save
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Project;
