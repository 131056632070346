import React, { Component } from "react";
import { Input, Card, CardBody } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { viewComponent } from "../services/APICall";
import { API_URL } from "../config";
import Tag from "../components/Tag";
import { toast } from "react-toastify";
import { InterestLoader } from "../constants/Loader";

const $ = window.$;
class InterestTagsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: []
    };
  }

  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show" });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide" });
    }
    let response = await viewComponent("/interest");
    if (response !== null && response.success === true) {
      let interestInfo = await response.data.interest;
      this.setState({ tags: interestInfo, success: true });
      // check length of tags, if reaches 6, then disable input field with message
      if (this.state.tags.length >= 6) this.setState({ invalid: true, message: "You've added maximum allowed interest" });
    } else {
      this.setState({ success: false });
    }
  };

  render() {
    return (
      <>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <a id="link" data-toggle="collapse" href="#collapse-intrst">
                  Interests
                </a>
              </h4>
            </div>
            <div id="collapse-intrst" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <div>
                        <InterestLoader width={345} />
                      </div>
                    ) : (
                      <>
                        {this.state.tags.length === 0 ? (
                          <p>You've not added anything ..</p>
                        ) : (
                          this.state.tags.map((tag, key) => <Tag remove={this.remove} key={key} tag={tag} />)
                        )}
                        {this.state.inp !== undefined ? <Tag tag={this.state.inp} /> : <></>} {/*FIXME: optional*/}
                        <Input
                          disabled={this.state.invalid}
                          className="intTagInput"
                          type="text"
                          placeholder={this.state.invalid !== undefined && this.state.invalid === true ? this.state.message : "e.g: Cricket, Music,"}
                          onKeyUp={this.handleOnKeyUp}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // this function will invoke on each key up ...
  handleOnKeyUp = async event => {
    event.target.value.length > 0 ? this.setState({ inp: event.target.value + " .." }) : this.setState({ inp: undefined }); //FIXME: optional
    // enter -> 13 || comma->188 || tab ->
    if (event.which === 13 || event.which === 188) {
      // remove white space and last entered comma ...
      let input = event.target.value.trim().split(",");
      if (input.length === 0 || input[0] === "") return;
      event.target.value = "";
      this.setState({ inp: undefined }); //FIXME: optional
      let tag = event.which === 188 ? [input[0]] : input;
      let response = await this.update({ interest: [...this.state.tags, ...tag] });
      if (response.success === true) {
        this.setState({ tags: [...this.state.tags, ...tag] });
        toast.success(`✔️ Your interest tag have been saved successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
        // check length of tags, if reaches 6, then disable input field with message
        if (this.state.tags.length >= 6) {
          this.setState({ invalid: true, message: "You've added maximum allowed interest" });
          setTimeout(() => toast.info("ℹ️ You have added maximum allowed Interest", { position: toast.POSITION.BOTTOM_RIGHT }), 1000);
        }
      }
    }
  };

  // this function will invoke on tags X click
  remove = async tagValue => {
    const { tags } = this.state;
    let interestTags = tags.length === 1 ? [] : tags.filter(tag => tag !== tagValue);
    await this.handleDelete(interestTags, tagValue);
  };

  // this function will communicate with database and deletes
  handleDelete = (interestTags, tagValue) => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "yes",
          onClick: async () => {
            let res = await this.update({ interest: interestTags });
            if (res.success === true) {
              this.setState({ tags: interestTags });
              toast.success(`️️️✔️ Interested tag '${tagValue}' has been removed successfully.`, { position: toast.POSITION.BOTTOM_RIGHT });
              if (this.state.tags.length < 6) this.setState({ invalid: false });
            }
          }
        },
        { label: "no!" }
      ]
    });
  };

  update = async interestObject => {
    let response = await fetch(API_URL + "/interest", {
      method: "PATCH",
      body: JSON.stringify(interestObject),
      headers: { "Content-Type": "application/json", at: localStorage.getItem("token") }
    });
    return await response.json();
  };
}

export default InterestTagsInput;
