import React, { Component } from "react";
import LoginCard from "../components/LoginCard";
import { Button } from "reactstrap";
import Price from "../components/Price";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showPrice: false };
  }

  handleClickPrice = () => {
    this.setState({ showPrice: true });
  };
  handleClickLogin = () => {
    this.setState({ showPrice: false });
  };
  render() {
    return (
      <div className="mb-5">
        <nav className="navbar fixed-top custom-fixed-top navbar-expand-lg navbar-light bg-white custom-nav">
          <a className="navbar-brand" target="__blank" href="https://stuworx.com">
            <img
              id="logo"
              src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype.png"
              className="logo logotype-negative hide"
              alt="STUWORX"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto ">
              {!this.state.showPrice && (
                <li className="nav-item">
                  <Button size="md" className="btn btn-block px-4" id="navbarDropdownMenuLink" onClick={this.handleClickPrice}>
                    Price
                  </Button>
                </li>
              )}
              {this.state.showPrice && (
                <li className="nav-item">
                  <Button size="md" className="btn btn-block px-4" id="navbarDropdownMenuLink" onClick={this.handleClickLogin}>
                    Login
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </nav>

        {this.state.showPrice && <Price />}
        {!this.state.showPrice && <LoginCard />}
      </div>
    );
  }
}

export default LoginPage;
