import React, { Component } from "react";
import { Card, Row, Col, CardHeader, CardBody, FormFeedback, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { stuworxAuthentication, userAuthentication } from "../services/APICall";
import { parseJwt } from "../constants/Constant";

import SocialLogin from "./SocialLogin";

class LoginCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      givenName: "",
      familyName: "",
      showModal: false,
      redirect: true
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    let data = {
      Email: this.state.email,
      Password: this.state.password
    };

    let stuworxResponse = await stuworxAuthentication("/authenticate", data);
    if (stuworxResponse && stuworxResponse.success) {
      localStorage.setItem("stuworx-token", stuworxResponse.token);
    } else {
      alert(stuworxResponse.message);
      return;
    }
    //To get token from URL
    // let tokenFromParams = this.props.location.query.token;

    let decodedToken = await parseJwt(localStorage.getItem("stuworx-token"));

    //to send token to backend replace body with decodedToken

    let apiResponse = await userAuthentication("/auth-stuworx", decodedToken);

    if (apiResponse.success) {
      localStorage.setItem("token", apiResponse.data.token);
      this.props.history.push("/");
    } else {
      toast.info(`🚫 Please enter valid Email Id / Password`, { position: toast.POSITION.BOTTOM_RIGHT });
      return;
    }
  };

  render() {
    return (
      <div>
        <Row className="justify-content-center align-items-center" id="loginPage">
          <Col md="6" sm={8} xs={12} id="loginPagePadding">
            <Card className="shadow border rounded">
              <CardHeader className="text-center" id="cardHeader">
                {/* <img
                  id="logo"
                  src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype.png"
                  className="logo logotype-negative hide"
                  alt="STUWORX"
                /> */}
                <h5 className="text-center h5">Login to your account</h5>
              </CardHeader>
              <CardBody>
                {/* <p className="text-center h5">Login to your account</p> */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="existingMail">E-Mail </Label>
                    <Input
                      type="email"
                      id="existingMail"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      placeholder="abc12@example.com"
                      required
                    />
                    <FormFeedback>Invalid Email</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="existingPassword">Password </Label>
                    <Input
                      type="password"
                      id="existingPassword"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      placeholder="abc@123"
                      required
                    />
                  </FormGroup>
                  <Button id="saveBtn" className="btn btn-block">
                    <i className="fas fa-sign-in-alt" />
                    &nbsp; LOGIN
                  </Button>
                  <div className="d-flex justify-content-center pt-1">
                    <div className="shape-left float-left" />
                    <div className="or">&nbsp; Or login with &nbsp;</div>
                    <div className="shape-right float-right" />
                  </div>
                  <SocialLogin />
                  <div className="text-center mt-4">
                    Don't have account?
                    <a target="__blank" href="https://stuworx.com/">
                      Register
                    </a>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(LoginCard);
