import { API_URL, STUWORX_URL, LINKEDIN_URL } from "../config";
import { httpClient, httpLogin } from "./commonServices";
import { LINKEDIN_SECRET_KEY, LINKEDIN_ID, LINKEDIN_REDIRECT_URI, LINKEDIN_USER_PROFILE, LINKEDIN_USER_EMAIL } from "../constants/Constant";

export function refreshPage() {
  window.location.reload();
}
export async function stuworxAuthentication(route, data) {
  let response = await httpLogin(STUWORX_URL + route, "POST", data);
  return response;
}
export async function userAuthentication(route, data) {
  let response = await httpClient(API_URL + route, "POST", data);
  return response;
}
export async function viewComponent(route) {
  let response = await httpClient(API_URL + route, "GET");
  return response;
}
export async function deleteComponent(route, id) {
  let response = await httpClient(API_URL + route + id, "DELETE");
  return response;
}
export async function editComponent(route, id, data) {
  let editResponse = httpClient(API_URL + route + id, "PATCH", data);
  return editResponse;
}
export async function addComponent(route, data) {
  let addResponse = httpClient(API_URL + route, "PUT", data);
  return addResponse;
}
export async function captureSubscription(route, data) {
  let response = httpClient(API_URL + route, "POST", data);
  return response;
}
export async function checkRegistration(route, data) {
  let response = httpLogin(STUWORX_URL + route, "POST", data);
  return response;
}
export async function registeredSocialUser(route, data) {
  let response = httpLogin(STUWORX_URL + route, "POST", data);
  return response;
}
export async function getLinkedinDetails(route, data) {
  let response = httpLogin(API_URL + route, "POST", data);
  return response;
}
