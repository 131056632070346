export const Premium_Amount = "50";
export async function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
}
export const GOOGLE_CLIENT_ID = "822507212337-6rblhkkao9g8f2ih91uldto81h346n39.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "356852231835187";
export const LINKEDIN_ID = "81x3qb63pi8o7v";
export const LINKEDIN_SECRET_KEY = "pDOXhWs6sZMnl6R5";
export const LINKEDIN_REDIRECT_URI = "http://localhost:3002/linkedin";
export const DEFAULT_IMAGE_URL = "https://s3-ap-southeast-1.amazonaws.com/stuworx-dev-resources/ShareImages/PI128.jpg";
