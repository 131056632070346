import React, { Component } from "react";
import { Form, FormGroup, FormFeedback, Label, Input, Button } from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { editComponent, addComponent } from "../../services/APICall";
import { priBtnBgColor } from "../../constants/Colors";

class ManageCertification extends Component {
  constructor(props) {
    super(props);
    this.state = { certificateTitle: "" };
  }
  componentDidMount = async () => {
    if (this.props.selectedCertificate !== undefined) {
      this.setState({
        certificateTitle: this.props.selectedCertificate.certificateTitle,
        certificateDate: moment(this.props.selectedCertificate.certificateDate).format("YYYY-MM-DD"),
        link: this.props.selectedCertificate.link,
        certificateID: this.props.selectedCertificate._id,
        isEdit: true
      });
    } else {
      this.setState({ isEdit: false });
    }
  };
  handleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });

    switch (name) {
      case "certificateTitle": {
        const regExp = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        regExp.test(value)
          ? this.setState({
              certificateTitleValid: true,
              isCertificateTitleInValid: false,
              isEmpty: false,
              certificateTitleValidMessage: ""
            })
          : value.length > 0
          ? this.setState({
              certificateTitleValid: false,
              isCertificateTitleInValid: true,
              isEmpty: false,
              certificateTitleValidMessage: "Invalid entry use only alphabets."
            })
          : this.setState({ certificateTitleValid: false, isCertificateTitleInValid: true, isEmpty: true, certificateTitleValidMessage: "cannot be empty" });

        break;
      }
      case "certificateDate": {
        const date = new Date().getTime();
        const currentDate = new Date(value).getTime();
        if (currentDate < date) {
          this.setState({ isCertificateDateValid: true, isCertificateDateInvalid: false, isEmpty: false, certificateDateValidMessage: "" });
        } else {
          value.length > 0
            ? this.setState(
                {
                  isCertificateDateValid: false,
                  isCertificateDateInvalid: true,
                  isEmpty: false,
                  certificateDateValidMessage: "Select date correctly"
                },
                () => {
                  console.log("ihj", this.state.isCertificateDateInvalid);
                }
              )
            : this.setState({
                isCertificateDateValid: false,
                isCertificateDateInvalid: true,
                isEmpty: true,
                certificateDateValidMessage: "😑 Should not be empty."
              });
        }
        break;
      }
      case "link": {
        {
          const regExp = /^$|^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
          regExp.test(value)
            ? this.setState({
                linkValid: true,
                isLinkInValid: false,
                linkValidMessage: ""
              })
            : this.setState({
                linkValid: false,
                isLinkInValid: true,
                linkValidMessage: "Invalid url"
              });
        }
        break;
      }
      default:
        break;
    }
  };

  getValidURL = url => {
    if (/http:\/\//.test(url) || /https:\/\//.test(url)) return url;
    return "http://" + url;
  };

  handleSubmit = async () => {
    const { certificateTitle, certificateDate } = this.state;
    if (certificateTitle === undefined || certificateDate === undefined || certificateTitle === "" || certificateDate === "") {
      this.setState({ isEmpty: true, message: "Please fill these details" });
      return;
    }
    if (this.state.isCertificateTitleInValid === true) {
      this.setState({ certificateTitleValidMessage: "Please enter a valid name..!" });
      return;
    }
    if (this.state.isCertificateDateInvalid === true) {
      this.setState({ certificateDateValidMessage: "Please enter a valid date..!" });
      return;
    }
    if (this.state.isLinkInValid === true) {
      this.setState({ linkValidMessage: "Please enter a valid URL..!" });
      return;
    }
    const certificate = {
      certificateTitle: this.state.certificateTitle,
      certificateDate: this.state.certificateDate,
      link: this.state.link && this.getValidURL(this.state.link)
    };
    if (this.state.isEdit === false) {
      let response = await addComponent("/certificates", certificate);
      if (response && response.success) {
        this.props.onSave(response.data, true, undefined);
        toast.success("✔️ Certificates Added successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    } else {
      let response = await editComponent("/certificates/", this.state.certificateID, certificate);
      if (response && response.success) {
        this.props.onSave(response.data, false, this.state);
        toast.success("✔️ Certificates Updated successfully.", { position: toast.POSITION.BOTTOM_RIGHT });
      } else {
        toast.info(`🚫 Oops! unable to update.${response.message}`, { position: toast.POSITION.BOTTOM_RIGHT });
      }
    }
  };
  render() {
    return (
      <div>
        <Form>
          <FormGroup>
            <Label for="certificateInput">Certification On*</Label>
            <Input
              type="text"
              id="certificateInput"
              name="certificateTitle"
              value={this.state.certificateTitle}
              onChange={this.handleChange}
              placeholder="eg : ANDROID APPLICATION DEVELOPMENT"
              invalid={this.state.certificateTitle && this.state.certificateTitle.length > 0 ? this.state.isCertificateTitleInValid : this.state.isEmpty}
              valid={this.state.certificateTitleValid}
            />
            <FormFeedback>{this.state.certificateTitleValidMessage || this.state.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="certificateDate">Certification date*</Label>
            <Input
              type="date"
              id="certificateDate"
              name="certificateDate"
              value={this.state.certificateDate}
              onChange={this.handleChange}
              invalid={this.state.certificateDate && this.state.certificateDate.length > 0 ? this.state.isCertificateDateInvalid : this.state.isEmpty}
              valid={this.state.isCertificateDateValid}
            />
            <FormFeedback>{this.state.certificateDateValidMessage || this.state.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="certificateUrl">LINK:</Label>
            <Input
              type="url"
              id="certificateUrl"
              name="link"
              value={this.state.link}
              onChange={this.handleChange}
              invalid={this.state.link !== undefined && this.state.isLinkInValid}
              valid={this.state.linkValid}
            />
            <FormFeedback>{this.state.linkValidMessage}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Button onClick={this.handleSubmit} className="btn-block" style={{ backgroundColor: priBtnBgColor, border: "none" }}>
              Save
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default ManageCertification;
