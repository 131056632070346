import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { viewComponent } from "../../services/APICall";

class SkillToChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSkills: {}
    };
  }
  skill = [];
  componentDidMount = async () => {
    let response = await viewComponent("/skills");
    let selectedSkills = {};
    if (response !== null && response.success) {
      this.setState({
        skillInfo: response.data,
        selectedSkills: selectedSkills,
        success: true
      });
      if (this.props.edited === undefined) {
        response.data.forEach(element => {
          selectedSkills[element._id] = true;
        });
        this.state.skillInfo.map(current => this.skill.push(current._id));
        this.props.onGetSkill(this.skill);
      } else {
        response.data.forEach(element => {
          selectedSkills[element._id] = false;
        });
        this.props.edited.forEach(element => {
          selectedSkills[element] = true;
        });
        this.props.edited.map(current => this.skill.push(current));
        this.props.onGetSkill(this.skill);
      }
    } else {
      this.setState({
        skillInfo: [],
        success: false
      });
    }
  };
  handleChange = async skillId => {
    let skillInfo = {
      ...this.state.selectedSkills
    };
    skillInfo[skillId] = !skillInfo[skillId];

    this.setState({
      selectedSkills: skillInfo,
      touched: true
    });
    if (this.state.selectedSkills[skillId] === true) {
      this.skill = this.skill.filter(e => e !== skillId);
    }
    if (this.state.selectedSkills[skillId] === false) {
      this.skill.push(skillId);
    }
    this.props.onGetSkill(this.skill);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5"> Skills </div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.skillInfo.length > 0 ? (
                this.state.skillInfo.map((currentSkill, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentSkill._id}
                          name={currentSkill._id}
                          checked={this.state.selectedSkills[currentSkill._id] && this.state.selectedSkills[currentSkill._id]}
                          onChange={() => this.handleChange(currentSkill._id)}
                        />
                        <Label for={currentSkill._id}> {currentSkill.skillName} </Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6> You have not added any skills to choose. </h6> <div className="disabled"> Please add your Skills in Profile </div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SkillToChoose;
