import React, { Component } from "react";
import { Button, Row, Col, Form, FormGroup, FormFeedback, Label, CardHeader, Input, CardBody, Card } from "reactstrap";

import { stuworxAuthentication } from "../services/APICall";
import SocialLogin from "./SocialLogin";

export default class RegistrationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    };
  }
  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });

    switch (name) {
      case "firstName": {
        const regExp = /^([A-Za-z]+\s)*[A-Za-z]+$/;
        regExp.test(value.trim())
          ? this.setState({ firstNameValid: true, isFirstNameInvalid: false, isEmpty: false, firstNameValidMessage: "" })
          : value.length > 0
          ? this.setState({ firstNameValid: false, isFirstNameInvalid: true, isEmpty: false, firstNameValidMessage: "*invalid name" })
          : this.setState({ firstNameValid: false, isFirstNameInvalid: true, isEmpty: true, firstNameValidMessage: "*should not be empty" });
        break;
      }
      case "lastName": {
        const regExp = /^([A-Za-z]+\s)*[A-Za-z]+$/;
        regExp.test(value.trim())
          ? this.setState({ lastNameValid: true, isLastNameInvalid: false, isEmpty: false, lastNameValidMessage: "" })
          : value.length > 0
          ? this.setState({ lastNameValid: false, isLastNameInvalid: true, isEmpty: false, lastNameValidMessage: "*invalid name" })
          : this.setState({ lastNameValid: false, isLastNameInvalid: true, isEmpty: true, lastNameValidMessage: "*should not be empty" });
        break;
      }

      case "email": {
        const regExp = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        regExp.test(value.trim())
          ? this.setState({ emailValid: true, isEmailInvalid: false, isEmpty: false, emailValidMessage: "" })
          : value.length > 0
          ? this.setState({ emailValid: false, isEmailInvalid: true, isEmpty: false, emailValidMessage: "*invalid name" })
          : this.setState({ emailValid: false, isEmailInvalid: true, isEmpty: true, emailValidMessage: "*should not be empty" });
        break;
      }
      case "password": {
        const regExp = /^([A-Za-z]+\s)*[A-Za-z]+$/;
        regExp.test(value.trim())
          ? this.setState({ passwordValid: true, isPasswordInvalid: false, isEmpty: false, passwordValidMessage: "" })
          : value.length > 0
          ? this.setState({ passwordValid: false, isPasswordInvalid: true, isEmpty: false, passwordValidMessage: "*invalid name" })
          : this.setState({ passwordValid: false, isPasswordInvalid: true, isEmpty: true, passwordValidMessage: "*should not be empty" });
        break;
      }
      case "confirmPassword": {
        const regExp = /^([A-Za-z]+\s)*[A-Za-z]+$/;
        regExp.test(value.trim())
          ? this.setState({ confirmPasswordValid: true, isConfirmPasswordInvalid: false, isEmpty: false, confirmPasswordValidMessage: "" })
          : value.length > 0
          ? this.setState({ confirmPasswordValid: false, isConfirmPasswordInvalid: true, isEmpty: false, confirmPasswordValidMessage: "*invalid name" })
          : this.setState({ confirmPasswordValid: false, isConfirmPasswordInvalid: true, isEmpty: true, confirmPasswordValidMessage: "*should not be empty" });
        break;
      }
      default: {
      }
    }
    if (name === "confirmPassword") {
      if (this.state.password === value) {
        this.setState({
          confirmPasswordValid: true,
          isConfirmPasswordInvalid: false,

          confirmPasswordValidMessage: "password match"
        });
      } else {
        this.setState({
          confirmPasswordValid: false,
          isConfirmPasswordInvalid: true,
          confirmPasswordValidMessage: "password doesn't match"
        });
      }
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { firstName, lastName, email, password, confirmPassword } = this.state;
    if (
      firstName === undefined ||
      lastName === undefined ||
      email === undefined ||
      password === undefined ||
      confirmPassword === undefined ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      this.setState({ isEmpty: true, message: "should not be empty" });
      return;
    }

    const data = {
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      Email: this.state.email,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmPassword
    };
    let stuworxResponse = await stuworxAuthentication("/register", data);
    if (stuworxResponse && stuworxResponse.success) {
      this.props.history.push("/registration-pending");
    } else {
      alert(stuworxResponse.message);
      return;
    }
  };
  render() {
    return (
      <div>
        <Row className="justify-content-center" id="loginPage">
          <Col xm={12} sm={8} md={6} className="py-4">
            <Card outline className="shadow-lg rounded ">
              <CardHeader className="text-center" id="cardHeader">
                <img
                  id="logo"
                  src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype.png"
                  className="logo logotype-negative hide"
                  alt="STUWORX"
                />
              </CardHeader>
              <CardBody>
                <p className="text-center h5">Create your account</p>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="inputFirstName">First Name*</Label>
                        <Input
                          type="text"
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.handleInputChange}
                          id="inputFirstName"
                          placeholder="First Name"
                          valid={this.state.firstNameValid}
                          invalid={this.state.firstName && this.state.firstName.length > 0 ? this.state.isFirstNameInvalid : this.state.isEmpty}
                        />
                        <FormFeedback>{this.state.firstNameValidMessage || this.state.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="inputLastName">Last Name*</Label>
                        <Input
                          type="text"
                          name="lastName"
                          valid={this.state.lastNameValid}
                          invalid={this.state.lastName && this.state.lastName.length > 0 ? this.state.isLastNameInvalid : this.state.isEmpty}
                          value={this.state.lastName}
                          onChange={this.handleInputChange}
                          id="inputLastName"
                          placeholder="Last Name"
                        />
                        <FormFeedback>{this.state.lastNameValidMessage || this.state.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="inputEmail">Email*</Label>
                    <Input
                      type="email"
                      name="email"
                      valid={this.state.emailValid}
                      invalid={this.state.email && this.state.email.length > 0 ? this.state.isEmailInvalid : this.state.isEmpty}
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      id="inputEmail"
                      placeholder="eg: mail@example.com"
                    />
                    <FormFeedback>{this.state.emailValidMessage || this.state.message}</FormFeedback>
                  </FormGroup>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="inputPassword">Password*</Label>
                        <Input
                          type="password"
                          name="password"
                          valid={this.state.passwordValid}
                          invalid={this.state.password && this.state.password.length > 0 ? this.state.isPasswordInvalid : this.state.isEmpty}
                          value={this.state.password}
                          onChange={this.handleInputChange}
                          id="inputPassword"
                          placeholder="eg: abc@123"
                        />
                        <FormFeedback>{this.state.passwordValidMessage || this.state.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="inputConfirmPassword">confirm Password*</Label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          valid={this.state.confirmPasswordValid}
                          invalid={
                            this.state.confirmPassword && this.state.confirmPassword.length > 0 ? this.state.isConfirmPasswordInvalid : this.state.isEmpty
                          }
                          value={this.state.confirmPassword}
                          onChange={this.handleInputChange}
                          id="inputPassword"
                          placeholder="eg: abc@123"
                        />
                        <FormFeedback>{this.state.confirmPasswordValidMessage || this.state.message}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="buttonContainer">
                    <Button id="submitBtn" className=" btn-block">
                      <i className="fas fa-sign-in-alt" />
                      &nbsp; Create account
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center pt-1">
                    <div className="shape-left float-left" />
                    <div className="or">&nbsp; Or signup with &nbsp;</div>
                    <div className="shape-right float-right" />
                  </div>
                  <SocialLogin />
                  <div>
                    Already have an account?<a href="/login">Login</a>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
