import React, { Component } from "react";
import { Card, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import Moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ManageCertification from "../components/manage/ManageCertifications";
import { toast } from "react-toastify";
import { viewComponent, deleteComponent } from "../services/APICall";
import BtnSpan from "../components/BtnSpan";
import { OneLineLoader } from "../constants/Loader";
const $ = window.$;
class CertificationInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }
  componentDidMount = async () => {
    if ($(window).width() >= 768) {
      $('a[data-toggle="collapse"]').click(function(e) {
        e.stopPropagation();
      });
      this.setState({ collapse: "panel-collapse  collapse show", mobile: false });
    } else {
      this.setState({ collapse: "panel-collapse  collapse hide", mobile: true });
    }
    let response = await viewComponent("/certificates");
    if (response !== null && response.success) {
      this.setState({ certificateInfo: response.data, success: response.success });
    } else {
      this.setState({ certificateInfo: [], success: false });
    }
  };
  handleAdd = async () => {
    this.setState({ showModal: true, currentCertificate: undefined });
  };
  handleEdit = async certificate => {
    this.setState({ showModal: true, currentCertificate: certificate });
  };
  handleDelete = async certificate => {
    confirmAlert({
      title: "Do you want to delete",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let response = await deleteComponent("/certificates/", certificate._id);
            if (response.success) {
              let certificateObj = this.state.certificateInfo.filter(data => data !== certificate);
              this.setState({ certificateInfo: certificateObj });
              toast.success(`️️️✔️ Certification of '${certificate.certificateTitle}' has been removed successfully.`, {
                position: toast.POSITION.BOTTOM_RIGHT
              });
            }
          }
        },
        { label: "No" }
      ]
    });
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };
  save = (newCertificate, isAdd, oldCertificate) => {
    let { certificateInfo } = this.state;
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
    if (isAdd) {
      this.setState({ certificateInfo: [...certificateInfo, newCertificate] });
    } else {
      let temp = certificateInfo;
      let index = temp.findIndex(obj => obj._id === oldCertificate.certificateID);
      temp.splice(index, 1, newCertificate);
      this.setState({ certificateInfo: temp });
    }
  };
  handleMouseHover = (isHovering, value) => {
    this.setState({
      [isHovering]: value
    });
  };
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div>
        <div className="panel-group">
          <div className="panel panel-default">
            <div className="panel-heading">
              <h4 className="panel-title">
                <BtnSpan isHovering={true} onClickFunction={this.handleAdd} BtnSpanClass={"fas fa-plus float-right mr-3"} />
                <a id="link" data-toggle="collapse" href="#collapse-cer">
                  Certificates
                </a>
              </h4>
            </div>
            <div id="collapse-cer" className={this.state.collapse}>
              <div className="panel-body">
                <Card>
                  <CardBody>
                    {this.state.success === undefined ? (
                      <OneLineLoader width={550} />
                    ) : this.state.certificateInfo.length > 0 ? (
                      <div>
                        <div>
                          <div className="py-2">
                            {this.state.certificateInfo.map((currentCertificate, key) => {
                              return (
                                <div
                                  key={key}
                                  className={key === 0 ? "p-2 " : "p-2 border-top"}
                                  onMouseEnter={() => this.handleMouseHover("isHovering" + key, true)}
                                  onMouseLeave={() => this.handleMouseHover("isHovering" + key, false)}
                                >
                                  <span className="float-right">
                                    <BtnSpan
                                      isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                      onClickFunction={() => this.handleEdit(currentCertificate)}
                                      BtnSpanClass={"fas fa-pen mr-3"}
                                    />
                                    <BtnSpan
                                      isHovering={!this.state.mobile ? this.state["isHovering" + key] : true}
                                      onClickFunction={() => this.handleDelete(currentCertificate)}
                                      BtnSpanClass={"fas fa-trash"}
                                    />
                                  </span>
                                  <a href={currentCertificate.link !== "" ? currentCertificate.link : undefined}>{currentCertificate.certificateTitle}</a>&nbsp;
                                  in &nbsp;
                                  {Moment(currentCertificate.certificateDate).format("YYYY")}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <h6>
                            <i className="far fa-frown" />
                            &nbsp; Sorry! couldn't find any details
                          </h6>
                        </div>
                        <p className="text-muted">Please add your certifications...!</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <div>
                  <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static">
                    <ModalHeader close={closeBtn} className="modalStyle">
                      Certificate details
                    </ModalHeader>
                    <ModalBody>
                      <ManageCertification selectedCertificate={this.state.currentCertificate} onSave={this.save} />
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CertificationInfo;
