import React, { Component } from "react";
import { Card, CardBody, Button, ListGroup, ListGroupItem } from "reactstrap";
import { Premium_Amount } from "../../constants/Constant";
import { captureSubscription, viewComponent } from "../../services/APICall";
import { InstamojoPaymentLink, Cash_Free, PayU, RAZOR_PAY_KEY } from "../../config";
import { toast } from "react-toastify";
import { generateCashFreeCheckSumToken, CheckCashFreeResponse, GeneratePayUCheckSum, SaveInstamojoResponse, SavePayUResponse } from "../../services/PaymentService";
import UserDetailsDialog from "../UserDetailsDialog";
class ManagePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: {},
      isUserDetailsDialogOpen: false,
      selectedPaymentType: ""
    };
  }
  componentDidMount = async () => {
    let response = await viewComponent("/personal-info");
    if (response !== null && response.success === true) {
      this.setState({ userProfile: response.data });
    }
  }
  handlePayment = () => {
    this.props.onClose();
  };
  payByRazorPay = async () => {
    if (this.props.premiumUser === false) {
      this.setState(
        {
          payment: {
            amount: Premium_Amount
          }
        },
        () => {
          var options = {
            key: RAZOR_PAY_KEY,
            amount: Premium_Amount * 100,
            name: "STUWORX",
            description: "User Subscription",
            image: "https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logo.png",
            handler: response => {
              this.captureSubscription(response);
            },
            theme: {
              color: "#5F5DA2"
            }
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
      );
    } else {
      alert("Already a Premium User");
    }
  };
  captureSubscription = async response => {
    let obj = {
      paymentId: response ? response.razorpay_payment_id : undefined,
      amount: Premium_Amount * 100
    };
    let captureResp = await captureSubscription("/payment-capture", obj);
    if (captureResp && captureResp.success) {
      localStorage.setItem("token", captureResp.data.token);
      this.props.onClose();
      this.props.updateProUser(true);
      if (captureResp && captureResp.data && captureResp.data.token) {
        localStorage.setItem("stuworx-token", captureResp.data.token);
      }
    } else {
      alert("Error while capturing payment");
    }
  };

  payByInstamojo = () => {
    window.Instamojo.configure({
      handlers: {
        onSuccess: this.captureInstamojoRespSuccess,
        onFailure: this.captureInstamojoRespError
      }
    });

    window.Instamojo.open(InstamojoPaymentLink);
  }
  captureInstamojoRespSuccess = async (response) => {
    let result = await SaveInstamojoResponse(response);
    if (result.success) {
      localStorage.setItem("token", result.data.token);
      this.props.updateProUser(true);
      this.props.onClose();
      localStorage.setItem("stuworx-token", result.data.token);
    } else {
      toast.info("🚫 Error while saving payment response", { position: toast.POSITION.BOTTOM_RIGHT });
    }
  }
  captureInstamojoRespError = (errorResponse) => {
    console.log(errorResponse)
    toast.info("🚫 Error while paying with instamojo", { position: toast.POSITION.BOTTOM_RIGHT });
  }

  payByCashFree = async (userData) => {
    var data = {};
    data.orderId = new Date().getTime();
    data.orderAmount = Number(Premium_Amount);

    // data.customerName = this.state.userProfile.FirstName ? `${this.state.userProfile.FirstName} ${this.state.userProfile.LastName}` : "N/A";
    // // if (this.state.userProfile.Phone) {
    // data.customerPhone = this.state.userProfile.Phone ? this.state.userProfile.Phone : "9160352626";
    // // }
    // if (this.state.userProfile.Email) {
    //   data.customerEmail = this.state.userProfile.Email;
    // }

    data.returnUrl = "";
    // data.notifyUrl = "https://mysite.com/payment/notify";
    data.customerName = userData.name;
    data.customerPhone = userData.phone;
    data.customerEmail = userData.email;
    data.returnUrl = "";
    data.appId = Cash_Free.App_Id;
    data.paymentModes = "";
    let obj = {
      appId: data.appId,
      orderId: data.orderId,
      orderAmount: data.orderAmount,
      returnUrl: data.returnUrl,
      paymentModes: data.paymentModes
    };

    let result = await generateCashFreeCheckSumToken(obj);
    if (result.success) {
      console.log(result);
      data.paymentToken = result.data.signature;
      var config = {};
      config.layout = { view: "popup", width: "650" };

      config.mode = Cash_Free.Mode;
      var response = window.CashFree.init(config);
      let isCashFreeInitialized = false;
      if (response.status === "OK") {
        isCashFreeInitialized = true;
      } else {
        toast.info("🚫 Error while initializing cash free", { position: toast.POSITION.BOTTOM_RIGHT });
      }

      if (isCashFreeInitialized) {
        window.CashFree.makePayment(data, async (event) => {
          var eventName = event.name;
          switch (eventName) {
            case "PAYMENT_REQUEST":
              console.log(event.message);
              break;
            case "PAYMENT_RESPONSE":
              if (event && event.response && event.response.txStatus && event.response.txStatus.toLowerCase() === "success") {
                let paymentResponse = await CheckCashFreeResponse(event.response);
                if (paymentResponse.success) {
                  this.props.updateProUser(true);
                  this.props.onClose();
                  localStorage.setItem("stuworx-token", paymentResponse.data.token);
                  toast.success("✔️ Payment done.", { position: toast.POSITION.BOTTOM_RIGHT });
                } else {
                  toast.info("🚫 " + paymentResponse.message, { position: toast.POSITION.BOTTOM_RIGHT });
                }
              } else if (!event || !event.response || !event.response.txStatus || event.response.txStatus.toLowerCase() !== "cancelled") {
                toast.info("🚫 Error while payment", { position: toast.POSITION.BOTTOM_RIGHT });
              }
              console.log(event.message);
              break;
            default:
              console.log(event.message);
          };
        });
      }
    } else {
      toast.info("🚫 Error while generating checksum token", { position: toast.POSITION.BOTTOM_RIGHT });
    }
  }

  payByPayU = async (userData) => {
    let data = {
      key: PayU.key,
      productinfo: 'Stuworx Resume Subscription',
      surl: 'http://localhost:3000/templates',
      furl: 'http://localhost:3000/templates',
      // mode: 'dropout',
      amount: Number(Premium_Amount).toString(),
      udf5: ""
    }
    data.txnid = new Date().getTime().toString();
    data.firstname = userData.name;
    data.email = userData.email
    data.phone = userData.phone;

    let checkSumResponse = await GeneratePayUCheckSum(data);
    if (checkSumResponse.success) {
      data.hash = checkSumResponse.data.signature
      var handler = {
        responseHandler: async (BOLT) => {
          console.log("response", BOLT);
          let result = await SavePayUResponse(BOLT.response);
          if (result.success) {
            this.props.updateProUser(true);
            this.props.onClose();
            localStorage.setItem("stuworx-token", result.data.token);
            toast.success("✔️ Payment done.", { position: toast.POSITION.BOTTOM_RIGHT });
          } else {
            toast.info("🚫 " + result.message, { position: toast.POSITION.BOTTOM_RIGHT });
          }
        },
        catchException: (BOLT) => {
          console.log("error", BOLT)
          // the code you use to handle the integration errors goes here
        }
      }
      window.bolt.launch(data, handler);
    } else {
      toast.info("🚫 Error while generating checksum token", { position: toast.POSITION.BOTTOM_RIGHT });
    }


  }
  handleCloseUserDialog = (isClose, data) => {
    this.setState({
      isUserDetailsDialogOpen: false
    });
    if (isClose) {
      switch (this.state.selectedPaymentType) {
        case "cashFree":
          this.payByCashFree(data);
          break;
        case "payu":
          this.payByPayU(data);
          break;
        default:
          break;
      }
    }
  }

  openUserDetailsDialog = (type) => {
    this.setState({
      isUserDetailsDialogOpen: true,
      selectedPaymentType: type
    });
  }

  render() {
    return (
      <div>
        {/* {this.props.premiumUser !== undefined ? ( */}
        <div>
          <div className="h3 text-center">Become A Premium User</div>
          <div>
            <Card>
              <CardBody className="text-center pt-4">
                <h4>₹{Premium_Amount} only</h4>
                <p />
                <p className="text-muted">One-time fee. Life-time of premium support and unlimited access to Premium Templates.</p>

                <ListGroup className="payment-types">
                  <ListGroupItem>
                    <img src="/images/razorpay.png" alt="razorPay" />
                    <Button color="link" onClick={this.payByRazorPay}>Pay</Button>
                  </ListGroupItem>
                  <ListGroupItem>
                    <img src="/images/instamojo.png" alt="instamojo" />
                    <Button color="link" onClick={this.payByInstamojo}>Pay</Button>
                  </ListGroupItem>
                  <ListGroupItem>
                    <img src="/images/cashfree.png" alt="cashfree" />
                    <Button color="link" onClick={() => this.openUserDetailsDialog("cashFree")}>Pay</Button>
                  </ListGroupItem>
                  <ListGroupItem>
                    <img src="/images/payu.png" alt="payu" />
                    <Button color="link" onClick={() => this.openUserDetailsDialog("payu")}>Pay</Button>
                  </ListGroupItem>
                </ListGroup>

              </CardBody>
              {/* <CardFooter id="cardFooter">
                <Button className="btn btn-block" id="next-button" onClick={this.handleSubscription}>
                  <i className="far fa-credit-card" />
                  &nbsp; Pay now
                </Button>
              </CardFooter> */}
            </Card>
          </div>
        </div>
        {/* ) : (
          <div>
            <Card>
              <CardBody className="text-center pt-4">
                <h4>ALREADY A PREMIUM USER</h4>
                <p className="pt-3">Thanks for subscribing us. Hope you liked our Premium templates.</p>
              </CardBody>
            </Card>
          </div>
        )} */}
        <div id="payment-div"></div>
        {this.state.isUserDetailsDialogOpen && (
          <UserDetailsDialog onClose={this.handleCloseUserDialog} profile={this.state.userProfile} />
        )}
      </div>
    );
  }
}

export default ManagePayment;
