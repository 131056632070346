import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
class UserDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {
                name: props.profile.FirstName ? `${props.profile.FirstName} ${props.profile.LastName}` : "",
                email: props.profile.Email ? props.profile.Email : "",
                phone: props.profile.Phone ? props.profile.Phone : ""
            },
            errors: {
                name: false,
                email: false,
                phone: false
            },
            validationMessages: {
                name: "",
                email: "",
                phone: ""
            },
        }
    }

    handleChange = (event, value) => {
        let eve = { ...event };
        this.setState(prevState => ({
            userData: {
                ...prevState.userData,
                [eve.target.name]: value ? value : eve.target.value
            }
        }), this.validateData);
    }
    validateData = (isClose) => {
        var errors = {
            name: false,
            email: false,
            phone: false
        },
            validationMessages = {
                name: "",
                email: "",
                phone: ""
            },
            hasErrors = false;
        const emailRegExp = /^([A-Za-z]+\s)*[A-Za-z]+$/;

        if (!this.state.userData.name) {
            errors.name = true;
            hasErrors = true;
            validationMessages.name = "Please add name";
        }
        if (!this.state.userData.email) {
            errors.email = true;
            hasErrors = true;
            validationMessages.email = "Please add email";
        } else if (emailRegExp.test(this.state.userData.email.trim())) {
            errors.email = true;
            hasErrors = true;
            validationMessages.email = "Please add valid email";
        }
        if (!this.state.userData.phone) {
            errors.phone = true;
            hasErrors = true;
            validationMessages.phone = "Please add phone";
        } else if (this.state.userData.phone.toString().length !== 10) {
            errors.phone = true;
            hasErrors = true;
            validationMessages.phone = "Please add valid phone";
        }
        this.setState({
            errors: errors,
            validationMessages: validationMessages
        })
        if (!hasErrors && isClose) {
            this.props.onClose(true, { ...this.state.userData })
        }
    }
    render() {
        const closeBtn = (
            <button id="closeBtn" className="close" onClick={this.props.onClose}>
                &times;
            </button>
        );
        return (
            <Modal isOpen={true} toggle={this.props.onClose} backdrop="static" size="md">
                <ModalHeader close={closeBtn} className="modalStyle">
                    User Details
                </ModalHeader>
                <ModalBody className="modalScroll">
                    <Form className="container">
                        <Row>
                            <div className="col-12">
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={this.state.userData.name}
                                        onChange={this.handleChange}
                                        placeholder="Name"
                                        invalid={this.state.errors.name}
                                        valid={this.state.errors.name}
                                    />
                                    <FormFeedback>{this.state.validationMessages.name}</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={this.state.userData.email}
                                        onChange={this.handleChange}
                                        placeholder="Email"
                                        invalid={this.state.errors.email}
                                        valid={this.state.errors.email}
                                    />
                                    <FormFeedback>
                                        {this.state.validationMessages.email}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                <FormGroup>
                                    <Label>Phone</Label>
                                    <Input
                                        type="number"
                                        name="phone"
                                        value={this.state.userData.phone}
                                        onChange={this.handleChange}
                                        placeholder="Phone"
                                        invalid={this.state.errors.phone}
                                        valid={this.state.errors.phone}
                                    />
                                    <FormFeedback>
                                        {this.state.validationMessages.phone}
                                    </FormFeedback>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    {/* <div className="float-right"> */}
                    <button type="button" className="btn mr-2 px-4" onClick={() => this.props.onClose(false)}>
                        Cancel
                    </button>
                    <button className="btn btn-primary px-4" type="button" onClick={() => this.validateData(true)}>
                        Next
                    </button>
                    {/* </div> */}
                </ModalFooter>
            </Modal>
        );
    }
}

export default UserDetailsDialog;