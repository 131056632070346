import React from "react";

const ErrorPage = () => (
  <div className="text-container">
    <div>
      <p className="notFound">
        <span className="four">4</span>
        <span>
          <img src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/error/404.png" alt="errorImg" className="ErrorImg" />
        </span>
        <span className="four">4</span>
      </p>
      <p class="not-found-text text-white">Oops! Looks like you are lost.</p>
      <br />
      <div class="back-btn-container">
        <a href="/" class="back-btn">
          <span />
          🏠
          <br />
          HOME
        </a>
      </div>
    </div>
    <img src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype_negative.png" alt="logo" class="stu-logo" />
  </div>
);

export default ErrorPage;
