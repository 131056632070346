import React, { Component } from "react";
import { Card, CardBody, Form, Spinner, FormGroup, Input, Label } from "reactstrap";
import { viewComponent } from "../../services/APICall";
class SelectCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedCertificates: {} };
  }
  certificates = [];
  componentDidMount = async () => {
    let response = await viewComponent("/certificates");
    if (response !== null && response.success) {
      let selectedCertificates = {};
      this.setState({ certificateInfo: response.data, selectedCertificates: selectedCertificates, success: true });
      if (this.props.edited === undefined) {
        response.data.forEach(element => {
          selectedCertificates[element._id] = true;
        });
        this.state.certificateInfo.map(current => this.certificates.push(current._id));
        this.props.onGetCertification(this.certificates);
      } else {
        response.data.forEach(element => {
          selectedCertificates[element._id] = false;
        });
        this.props.edited.forEach(element => {
          selectedCertificates[element] = true;
        });
        this.props.edited.map(current => this.certificates.push(current));
        this.props.onGetCertification(this.certificates);
      }

      // response.data.forEach(element => {
      //   selectedCertificates[element._id] = true;
      // });
    } else {
      this.setState({ certificateInfo: [], success: false });
    }
  };
  handleChange = async certificateId => {
    let certificateInfo = { ...this.state.selectedCertificates };
    certificateInfo[certificateId] = !certificateInfo[certificateId];

    this.setState({
      selectedCertificates: certificateInfo
    });
    if (this.state.selectedCertificates[certificateId] === true) {
      this.certificates = this.certificates.filter(e => e !== certificateId);
    }
    if (this.state.selectedCertificates[certificateId] === false) {
      this.certificates.push(certificateId);
    }
    this.props.onGetCertification(this.certificates);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5">Certificates</div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.certificateInfo.length > 0 ? (
                this.state.certificateInfo.map((currentCertificate, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentCertificate._id}
                          name={currentCertificate._id}
                          checked={this.state.selectedCertificates[currentCertificate._id] && this.state.selectedCertificates[currentCertificate._id]}
                          onChange={() => this.handleChange(currentCertificate._id)}
                        />
                        <Label for={currentCertificate._id}>{currentCertificate.certificateTitle} </Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>You have not added any Certificate details</h6>
                  <div className="disabled">Please add it in your Profile to choose</div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SelectCertificate;
