import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { viewComponent } from "../../services/APICall";

class SelectWorkExperience extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedWorkInfo: {} };
  }
  WorkExperience = [];
  componentDidMount = async () => {
    let response = await viewComponent("/experience-details");
    if (response !== null && response.success) {
      let selectedWorkInfo = {};
      this.setState({ workInfo: response.data, selectedWorkInfo: selectedWorkInfo, success: true });

      if (this.props.edited === undefined) {
        response.data.forEach(element => {
          selectedWorkInfo[element._id] = true;
          this.state.workInfo.map(current => this.WorkExperience.push(current._id));
          this.props.onGetWork(this.WorkExperience);
        });
      } else {
        response.data.forEach(element => {
          selectedWorkInfo[element._id] = false;
        });
        this.props.edited.forEach(element => {
          selectedWorkInfo[element] = true;
        });
        this.props.edited.map(current => this.WorkExperience.push(current));
        this.props.onGetWork(this.WorkExperience);
      }
    } else {
      this.setState({ workInfo: [], success: false });
    }
  };
  handleChange = async workId => {
    let workInfo = { ...this.state.selectedWorkInfo };
    workInfo[workId] = !workInfo[workId];

    this.setState({
      selectedWorkInfo: workInfo
    });
    if (this.state.selectedWorkInfo[workId] === true) {
      this.WorkExperience = this.WorkExperience.filter(e => e !== workId);
    }
    if (this.state.selectedWorkInfo[workId] === false) {
      this.WorkExperience.push(workId);
    }
    this.props.onGetWork(this.WorkExperience);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5">Work Experience</div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.workInfo.length > 0 ? (
                this.state.workInfo.map((currentWorkInfo, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentWorkInfo._id}
                          name={currentWorkInfo._id}
                          checked={this.state.selectedWorkInfo[currentWorkInfo._id] && this.state.selectedWorkInfo[currentWorkInfo._id]}
                          onChange={() => this.handleChange(currentWorkInfo._id)}
                        />
                        <Label for={currentWorkInfo._id}>
                          {currentWorkInfo.jobTitle}&nbsp;at&nbsp;{currentWorkInfo.companyName}
                        </Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>You have not added any Work Experiences</h6>
                  <div className="disabled">Please add work experiences in your Profile</div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SelectWorkExperience;
