import React, { Component } from "react";
import { viewComponent } from "../services/APICall";
import { Button, Form, FormGroup, CardBody, CustomInput, Col, Row, Card, CardHeader, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import ModalImage from "react-modal-image";
import ManagePayment from "../components/manage/ManagePayment";
import { AWS_URL } from "../config";
import { ToastContainer } from "react-toastify";
class Templates extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      templateDetails: [],
      showModal: false,
      filter: "all"
    };
  }
  handleFilter = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value }, async () => {
      if (this.state.filter === "all") {
        let response = await viewComponent("/templates");
        if (response && response.success) {
          this.setState({ templateDetails: response.data, success: true });
        }
      }
      if (this.state.filter === "free") {
        let response = await viewComponent("/templates/free");
        if (response && response.success) {
          this.setState({ templateDetails: response.data, success: true });
        }
      }
      if (this.state.filter === "premium") {
        let response = await viewComponent("/templates/premium");
        if (response && response.success) {
          this.setState({ templateDetails: response.data, success: true });
        }
      }
    });
  };
  buyPremium = () => {
    this.setState({ showModal: true });
  };
  componentDidMount = async () => {
    let TempRes = await viewComponent("/templates");
    if (TempRes && TempRes.success) {
      this.setState({ templateDetails: TempRes.data, success: TempRes.success });
    } else {
      this.setState({ templateDetails: [], success: false });
    }
    let UserResponse = await viewComponent("/premium-status");
    if (UserResponse && UserResponse.success) {
      this.setState({ proUser: UserResponse.data.userPremiumStatus, UserResponseSuccess: true });
    } else {
      this.setState({ proUser: false, UserResponseSuccess: false });
    }
  };
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  handleLogout = () => {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  };
  homeRedirect = () => {
    this.props.history.push("/");
  };
  toggle = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  updateProUser = (isProUser) => {
    this.setState(previousState => ({
      proUser: isProUser,
      showModal: isProUser ? false : previousState.showModal
    }))
  }
  render() {
    const closeBtn = (
      <button id="closeBtn" className="close" onClick={this.toggle}>
        &times;
      </button>
    );
    return (
      <div className="homeDiv">
        <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white">
          <a className="navbar-brand" href="https://stuworx.com">
            <img
              id="logo"
              src="https://d2lnkz5z6ze3qt.cloudfront.net/v1911/images/stuworx-logo/logotype.png"
              className="logo logotype-negative hide"
              alt="STUWORX"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto ">
              <li className="nav-item p-1">
                {this.state.proUser === false ? (
                  <Button className="btn btn-block" id="btn-Buy" onClick={this.buyPremium}>
                    <i className="fas fa-crown" />
                    &nbsp;&nbsp; Buy Premium
                  </Button>
                ) : (
                    " "
                  )}
              </li>
              <li className="nav-item p-1">
                <Button className="btn btn-block" id="btn-logout" onClick={this.homeRedirect}>
                  <i className="fas fa-home" />
                  &nbsp;&nbsp; Home
                </Button>
              </li>
              <li className="nav-item p-1">
                <Button className="btn btn-block" id="btn-logout" onClick={this.handleLogout}>
                  <i className="fas fa-sign-out-alt" />
                  &nbsp;&nbsp; Logout
                </Button>
              </li>
            </ul>
          </div>
        </nav>

        <div>
          <div className="title">
            <div className="subDiv">
              <div className="d-flex  justify-content-between">
                <h4 className="pl-3">Templates</h4>
                <span className="float-right">
                  <Form className="d-flex">
                    <FormGroup className="mr-2">
                      <CustomInput
                        type="radio"
                        id="all"
                        name="filter"
                        label="All"
                        value="all"
                        onChange={this.handleFilter}
                        checked={this.state.filter === "all"}
                      />
                    </FormGroup>

                    <FormGroup className="mr-2">
                      <CustomInput
                        type="radio"
                        id="Free"
                        name="filter"
                        label="Free"
                        value="free"
                        onChange={this.handleFilter}
                        checked={this.state.filter === "free"}
                      />
                    </FormGroup>
                    <FormGroup className="mr-2">
                      <CustomInput
                        type="radio"
                        id="Premium"
                        name="filter"
                        value="premium"
                        label="Paid"
                        onChange={this.handleFilter}
                        checked={this.state.filter === "premium"}
                      />
                    </FormGroup>
                  </Form>
                </span>
              </div>

              {this.state.success === undefined ? (
                <div>
                  <Spinner className="spinner" />
                </div>
              ) : this.state.templateDetails.length > 0 ? (
                <Row id="row" className="p-4 ">
                  {this.state.templateDetails.map((x, key) => {
                    return (
                      <Col key={key} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card className="m-2">
                          <div className="ribbon">
                            <span>
                              {x.premiumStatus ? (
                                <>
                                  <i className="fas fa-crown" />
                                  &nbsp;Premium
                                </>
                              ) : (
                                  ""
                                )}
                            </span>
                          </div>
                          <CardHeader className="tempCard">{x.displayName}&nbsp;&nbsp;</CardHeader>
                          <CardBody>
                            <ModalImage
                              small={`${AWS_URL}${x.imageUrl}`}
                              large={`${AWS_URL}${x.imageUrl}`}
                              alt={x.displayName}
                              hideDownload={true}
                              hideZoom={false}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                    ""
                  )}
            </div>
            <ToastContainer hideProgressBar={true} />
            <Modal isOpen={this.state.showModal} toggle={this.toggle} backdrop="static" className="custom-payment-dialog">
              <ModalHeader close={closeBtn} className="modalStyle">
                Payment
              </ModalHeader>
              <ModalBody>
                <ManagePayment updateProUser={this.updateProUser} premiumUser={this.state.proUser} onClose={this.toggle} />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
export default Templates;
