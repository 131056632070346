import ContentLoader from "react-content-loader";
import React from "react";
export const ProfileLoader = props => (
  <ContentLoader height={236} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="96" y="125" rx="3" ry="3" width="117" height="6" />
    <rect x="35" y="150" rx="3" ry="3" width="255" height="6" />
    <rect x="60" y="175" rx="3" ry="3" width="208" height="6" />
    <rect x="50" y="200" rx="3" ry="3" width="230" height="6" />
    <circle cx="155" cy="55" r="53" />
  </ContentLoader>
);
export const Loader = props => (
  <ContentLoader height={110} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="0" y="15" rx="4" ry="4" width="117" height="6" />
    <rect x="0" y="35" rx="3" ry="3" width="85" height="6" />
    <rect x="0" y="60" rx="3" ry="3" width="350" height="6" />
    <rect x="0" y="80" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="100" rx="3" ry="3" width="201" height="6" />
  </ContentLoader>
);
export const AboutLoader = props => (
  <ContentLoader height={50} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="0" y="10" rx="3" ry="3" width="350" height="6" />
    <rect x="0" y="30" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="50" rx="3" ry="3" width="201" height="6" />
  </ContentLoader>
);

export const InterestLoader = props => (
  <ContentLoader height={90} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="17" y="30" rx="9" ry="80" width="77" height="30" />
    <rect x="112" y="30" rx="9" ry="80" width="77" height="30" />
  </ContentLoader>
);
export const TwoLineLoader = props => (
  <ContentLoader height={120} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="0" y="20" rx="4" ry="4" width="177" height="6" />
    <rect x="0" y="40" rx="3" ry="3" width="85" height="6" />
    <rect x="0" y="70" rx="4" ry="4" width="177" height="6" />
    <rect x="0" y="90" rx="3" ry="3" width="85" height="6" />
  </ContentLoader>
);
export const OneLineLoader = props => (
  <ContentLoader height={80} width={props.width} speed={2} primaryColor="#f3f3f3" secondaryColor="silver">
    <rect x="0" y="20" rx="4" ry="4" width="277" height="6" />
    <rect x="0" y="45" rx="3" ry="3" width="205" height="6" />
    <rect x="0" y="70" rx="4" ry="4" width="277" height="6" />
  </ContentLoader>
);
export const HomePageLoader = () => (
  <ContentLoader height={190} width={850} speed={2} primaryColor="#f3f3f3" secondaryColor="#5F5DA2">
    <circle cx="80" cy="150" r="38" />
    <circle cx="180" cy="150" r="38" />
    <circle cx="280" cy="150" r="38" />
  </ContentLoader>
);
