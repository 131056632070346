import React, { Component } from "react";
import { Card, CardBody, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { viewComponent } from "../../services/APICall";

class SelectWebsites extends Component {
  constructor(props) {
    super(props);
    this.state = { SelectedWebsites: {} };
  }
  Websites = [];
  componentDidMount = async () => {
    let response = await viewComponent("/websites");
    if (response !== null && response.success) {
      let SelectedWebsites = {};
      this.setState({ websitesInfo: response.data.websites, SelectedWebsites: SelectedWebsites, success: true });

      if (this.props.edited === undefined) {
        response.data.websites.forEach(element => {
          SelectedWebsites[element] = true;
        });
        this.state.websitesInfo.map(current => this.Websites.push(current));
        this.props.onGetWebsite(this.Websites);
      } else {
        response.data.websites.forEach(element => {
          SelectedWebsites[element] = false;
        });
        this.props.edited.forEach(element => {
          SelectedWebsites[element] = true;
        });
        this.props.edited.map(current => this.Websites.push(current));
        this.props.onGetWebsite(this.Websites);
      }
    } else {
      this.setState({ websitesInfo: [], success: false });
    }
  };
  handleChange = async webId => {
    let websitesInfo = { ...this.state.SelectedWebsites };
    websitesInfo[webId] = !websitesInfo[webId];

    this.setState({
      SelectedWebsites: websitesInfo
    });
    if (this.state.SelectedWebsites[webId] === true) {
      this.Websites = this.Websites.filter(e => e !== webId);
    }
    if (this.state.SelectedWebsites[webId] === false) {
      this.Websites.push(webId);
    }
    this.props.onGetWebsite(this.Websites);
  };

  render() {
    return (
      <div className="py-3">
        <div className="h5">Websites</div>
        <Card>
          <CardBody>
            <Form>
              {this.state.success === undefined ? (
                <Spinner className="spinner" />
              ) : this.state.websitesInfo.length > 0 ? (
                this.state.websitesInfo.map((currentWebsite, index) => {
                  return (
                    <div key={index}>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          id={currentWebsite}
                          name={currentWebsite}
                          checked={this.state.SelectedWebsites[currentWebsite] && this.state.SelectedWebsites[currentWebsite]}
                          onChange={() => this.handleChange(currentWebsite)}
                        />
                        <Label for={currentWebsite}>{currentWebsite}</Label>
                      </FormGroup>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>You have not added any Websites</h6>
                  <div className="disabled">Please add it in your Profile to choose</div>
                </div>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SelectWebsites;
